import React, { useEffect, useState, useRef } from "react";
import DashboardSidebar from "./DashboardComp";
import DashboardTop from "./DashboardTop";
import useCurrentUser from "./useCurrentUser";
import axios from "axios";
import myApi from "./MyApi";
import PanelQuestions from "./panelQuestions.json";
import { getData, showErrorCard } from "../App";
import useIsMobile from "./useIsMobile";
import renewAccessToken from "./renewAccessToken";
import { render } from "@testing-library/react";
import {
  Navigate,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";
// import userMale from "../images/userMale.png";
// import userFemale from "../images/userFemale.png";
import { useActiveContext } from "./ActiveContext";
import Loader from "./Loader";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Overlay from "./Overlay";
import { currCountry, currLang } from "./utils";
import AddPincode from "./AddPincode";
import CircularImageWithProgress from "./CircularImageWithProgress";
// import TrendingSurvey from "./TrendingSurvey";

function ProfileNew() {

  
  useEffect(() => {
    // Handler to execute on resize
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight
      setWindowWidth(newWidth);
      // console.log(newHeight)
      if(newWidth>1500){
        setCircleSize(190)
      }

     else if(newHeight<400){
        setCircleSize(80)
      }
    else{
      setCircleSize(125)
    }
    
    };

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call the handler once on component mount to set the initial width
    handleResize();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [circleSize,setCircleSize] = useState(125);
  const [windowWidth, setWindowWidth] = useState();
  const [pinDis, setPinDis] = useState(false);
  const [points, setPoints] = useState({
    s1: 0,
    s2: 0,
    s3: 0,
    s4: 0,
    s5: 0,
    s6: 0,
  });

  const [questionList, setQuestionList] = useState(PanelQuestions);
  const [currQuestionList, setCurrQuestionList] = useState([]);
  const quePerPage = 3;
  // let slideNum = Math.round(PanelQuestions.length / quePerPage) + 1;
  const [slideNum, setSlideNum] = useState(0);
  const totalSlides = Array.from({ length: slideNum });
  const [currentUser, setCurrentUser] = useCurrentUser();
  const [personInfo, setPersonInfo] = useState(false);
  const exclusiveValues = ["96", "99", "exclusive"];
  const isMobile = useIsMobile();

  const [currSlide, setCurrSlideNum] = useState(0);
  const [questionState, setQuestionState] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [page, setPage] = useState(1);
  const [pageHead, setPageHead] = useState("About You");
  const [bio, setBio] = useState("There is no personality statement");
  const { active, setActive, darkTheme } = useActiveContext();
  const [uploadDis, setUploadDis] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageHeadList, setPageHeadList] = useState([
    // 'About you',
    // 'More about you',
    // 'About your household',
    // 'Medical Status',
    // 'Your Interest',
    // 'Your favourites',
    // 'Travel',
    // 'Appliances'
  ]);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);
  const [perSliderDis, setPerSliderDis] = useState(false);
  const [gender, setGender] = useState();
  const [pinError, setPinError] = useState(false);
  const [finalQuestionList, setFinalQuestionList] = useState([]);
  const [allQuestions, setAllQuestions] = useState({});
  const [userProfileData,setUserProfileData] = useState([]);
  const path = useLocation();

  useEffect(() => {
    // console.log(path.pathname);
    if (path.pathname === "/profile") {
      let userImgCont = document.querySelector(".guestDiv");
      if (userImgCont) {
        userImgCont.addEventListener("click", () => {
          if (path.pathname === "/profile") {
            const icon = document.querySelector(
              ".sliderCont.sliderIcons .icon"
            );
            // console.log(icon);
            if (icon) {
              icon.click();
            }
          }
        });
      }
    }
  }, []);

  // const [allQuestions,setAllQuestions] = useState([]);
  useEffect(() => {
    setFinalQuestionList(currQuestionList);
  }, [currQuestionList]);



  const getCurrentPageQuestions = (pageHeadData,queList)=>{
  //  console.log(pageHeadData)
   setPageHead(pageHeadData[currSlide].translate);
   let currPageData = pageHeadData.find(f=>f.pageNo===currSlide+1)
   let currQueList = currPageData.QuestionList;
  const questionList = queList || allQuestions
   const filteredObj = Object.keys(questionList)
   .filter(key => currQueList.includes(key))  // Check if the key exists in the array
   .reduce((acc, key) => {
     acc[key] = questionList[key];                   // Build the new object with filtered keys
     return acc;
   }, {});

   return filteredObj
  //  console.log(currPageData)
  //  console.log(currQueList)
  }
  const replaceAllQuestionList = (questionsList)=>{
    // console.log(questionList)
  }
  const getNewQuestionList = async () => {
    const language = await currLang();
    const country = await currCountry();
    const newQuestionList = await getData(
      `/panel/panelVaribale/India?Page=${currSlide + 1}&lang=${language}`
    );
    // setCurrQuestionList(newQuestionList?.data?.QuestionList);
    // console.log(newQuestionList.data.questionList);
    setFinalQuestionList(newQuestionList?.data?.QuestionList);
    replaceAllQuestionList(newQuestionList?.data?.QuestionList)
    setLoading(false);
  };

  const getPageHeader = async () => {
    const language = await currLang();
    const country = await currCountry();
    const pageHeaderResp = await getData(
      `/panel/pageHeader/${country}?lang=${language}`
    );
    let data = pageHeaderResp.data?.PageHList;
    // console.log(data)
    setPageHeadList(data);
    setSlideNum(data.length);
    return data;
  };

  // const getAllQuestions = async () => {
  //   // alert("called")
  //   const token = localStorage.getItem("accessToken");
  //   let country = await currCountry();
  //   let language = await currLang();
  //   let allQueArr = [];
  //   if (currSlide == 0) {
  //     for (let i = 0; i < pageHeadList.length; i++) {
  //       let page = i + 1;
  //       // console.log(page)
  //       let response = await myApi.get(
  //         `/panel/panelVaribale/${country}?Page=${page}&lang=${language}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       );
  //       let pageQue = response.data;
  //       allQueArr.push(pageQue);
  //     }
  //     setAllQuestions(allQueArr);
  //     if (allQueArr.length !== 0) {
  //       localStorage.setItem("questionList", JSON.stringify(allQueArr));
  //     }
  //   }
  // };

  useEffect(() => {
    // getAllQuestions();
  }, [pageHeadList]);

  useEffect(() => {
    if (currentUser) {
      setGender(currentUser.result.gender);
      setSelectedImage(currentUser.result.profilePic);
    }
  }, [currentUser]);

  useEffect(() => {
    setFinalQuestionList([])
    getApi(currSlide);
  }, [currSlide]);

  const getObjectByKey = (panelData, key) => {
    // console.log(panelData[key])
    return panelData[key];
  };

  const dependentQuestionChange = (val) => {
  //   // val == 85 ? setDques(true) : setDques(false);

  //   getNewQuestionList();
  };
  const changeQuestionColor = (e) => {
    // let questionText = e.target.parentNode.parentNode.querySelector(".questionText");
    // questionText.setAttribute("style","background: rgb(63, 173, 138); color: white;")
  };
  const getOpenEndValue = async (qLabel) => {
    const newData = await getNewUserData();
    const result = getObjectByKey(newData.result.panelData, qLabel);
    // console.log(result);
    if(result.openEndText === 'null') return ''
    return result.openEndText;
  };
  const changeBasicInfo = (label, type, val) => {
    if (type === "Single") {
      let valueArr = [val];
      selectionChange(label, valueArr);
    }

    if (type === "numeric") {
      if (String(val).length < 6) {
        showErrorCard("Pincode should be of 6 Characters", "error");
      }
      let valueArr = [val];
      selectionChange(label, valueArr);
    }
  };

  const postData = async (url, data) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };
  const patchData = async (url, data) => {
    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.patch(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      // console.log(err);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };
  // const getCurrPageQuestions = (questionList)=>{

  //   for(let i=0;i<questionList.length;i++){
  //     console.log(questionList)
  //   }
  // }

  const getApi = async (currSlide) => {
    setLoading(true);
   let pageHeadData =  await getPageHeader();
    // console.log(currQuestionList.length)
    let newData;
      if(currSlide===0 && currQuestionList.length===0){
        setCurrQuestionList([])
        const token = localStorage.getItem("accessToken");
        let country = await currCountry();
        let language = await currLang();
        let response = await myApi.get(
            `/panel/panelVaribale/${country}?lang=${language}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
             )
            newData = response.data.QuestionList
            // console.log(newData)
        setAllQuestions(newData);
      }
  


  let list =  getCurrentPageQuestions(pageHeadData,newData);
  // console.log(list)
   setCurrQuestionList(list)


        // setCurrQuestionList(currQuestions)
        let userResponse = userProfileData;
        if(userProfileData.length==0){
            userResponse = await getNewUserData();
            setUserProfileData(userResponse)
        }
    if (currSlide == 0) {
      setTimeout(async () => {
        setLoading(true);
        if (userResponse == null) {
          window.location.reload();
          return;
        }
        let userResp = await userResponse.result;
        let bioPoints = userResp.bioPoints;
        bioPoints = JSON.parse(bioPoints);
        points.s1 = bioPoints[0];
        points.s2 = bioPoints[1];
        points.s3 = bioPoints[2];
        points.s4 = bioPoints[3];
        points.s5 = bioPoints[4];
        points.s6 = bioPoints[5];
        setBio(userResp.bio);

        let allDataField = document.querySelectorAll(".dataField");
        for (let i = 0; i < allDataField.length; i++) {
          let qLabel = allDataField[i].getAttribute("qLabel");
          let questionData = userResp.panelData[qLabel];
          // console.log(questionData)
          let selectedOption = questionData?.option;
          allDataField[i].value = selectedOption[0];
        }

        setLoading(false);
      }, 10);
    }

    if (currSlide >= 1) {
      setTimeout(async () => {
        if (userResponse == null) {
          window.location.reload();
          return;
        }
        let userResp = await userResponse.result;
        let single = document.querySelectorAll(".Single");
        let number = document.querySelectorAll(".Number");
        for (let i = 0; i < single.length; i++) {
          let qLabel = single[i].getAttribute("qlabel");
          let panelData = await userResp.panelData;
          let optionsArr = panelData[qLabel].option;
          single[i].value = optionsArr[0];
        }

        for (let i = 0; i < number.length; i++) {
          let qLabel = number[i].getAttribute("qlabel");
          // console.log(qLabel);
          let panelData = await userResp.panelData;
          let optionsArr = panelData[qLabel].option;
          number[i].value = optionsArr[0];
        }
        const newData = await userProfileData;
        // console.log(newData)
        changeBtnText(newData.result.panelData);
        setLoading(false);
      }, 10);
    }
    setLoading(false)
  };

  const changeBtnText = (panelData) => {
    setLoading(true);
    // console.log('entered')
    // console.log(panelData)
    let multi = document.querySelectorAll(".Multi");
    // console.log(multi.length)
    for (let i = 0; i < multi.length; i++) {
      let qLabel = multi[i].getAttribute("qLabel");
      let optionsArr = getOptionNum(qLabel, panelData);

      let allMultiOptions = document.querySelectorAll(
        `.multiOptions[qlabel='${qLabel}']`
      );
      // console.log(allMultiOptions.length)
      let btnText = "";
      allMultiOptions.forEach(async (elem) => {
        let value = parseInt(elem.getAttribute("value"));
        if (optionsArr.includes(value)) {
          elem.click();
          if (elem.getAttribute("type") === "open_end") {
            btnText +=
              (await getOpenEndValue(elem.getAttribute("qlabel"))) + ", ";
          } else {
            btnText += elem.innerText + ", ";
          }
          // console.log(btnText)
          let finalText = btnText.slice(0, -2);
          // console.log(finalText)
          multi[i].innerText = finalText;
          let finalTextLen = finalText.length;
          finalText = finalText.slice(0, 15);
          multi[i].innerText = finalText;
          if (finalTextLen > 25) multi[i].innerText = finalText + ".....";
        }
      });
    }
    setLoading(false);
  };

  const getOptionNum = (qlabel, panelData) => {
    // for (var i = 0; i < panelData.length; i++) {
    //   var obj = panelData[i];
    //   var objKey = Object.keys(obj)[0]; // Assuming each object contains only one key
    //   if (objKey === qlabel) {
    //     return obj[qlabel].option;
    //   }
    // }
    return panelData[qlabel].option;
    // return null; // If key not found
  };

  useEffect(() => {
    const initialStateArray = questionList.map((question) => ({
      label: question.label,
      value: false,
      btnText: "Select Multiple Options",
    }));

    setQuestionState(initialStateArray);
    // console.log(initialStateArray);

    if (currentUser) {
      setTimeout(() => {
        if (currentUser.result.picUrl) {
          setSelectedImage(currentUser.result.picUrl);
        }
      }, 100);
    }
  }, [currentUser]);

  const [queSlide, setQueSlide] = useState(1);

  const totalPoints = Array.from({ length: 5 });

  const selectionChange = async (qLabel, value, openText) => {
    let data;
    if (openText === undefined) {
      data = {
        panelData: { [qLabel]: { option: value } },
      };
    } else {
      data = {
        panelData: { [qLabel]: { option: value, openEndText: openText } },
      };
    }

    try {
      const token = localStorage.getItem("accessToken");
      const url = "/profile/userProfile";
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await myApi.patch(url, data, { headers });
      return response.data;
    } catch (error) {
      // console.error("Error occurred during patch request:", error);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }finally{
      await getNewUserData()
    }
  };

  const changeSelectedCircle = async (s, value) => {
    await setPoints((prev) => ({
      ...prev,
      [s]: value,
    }));
    points[s] = value;

    if (
      points.s1 == 0 ||
      points.s2 == 0 ||
      points.s3 == 0 ||
      points.s4 == 0 ||
      points.s5 == 0 ||
      points.s6 == 0
    )
      return;
    let bioResp;
    try {
      const token = localStorage.getItem("accessToken");
      bioResp = await myApi.get(
        `/panel/personality?personality_1=${points.s1}&personality_2=${points.s2}&personality_3=${points.s3}&personality_4=${points.s4}&personality_5=${points.s5}&personality_6=${points.s6}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (err) {
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }

    // let profileIntro = document.querySelector(".profileIntro");
    // profileIntro.innerText = bioResp.data.bio;
    setBio(bioResp.data.result);

    // console.log(bioResp);
    const updateBioResp = await patchData("/profile/userProfile", {
      bioPoints: [
        points.s1,
        points.s2,
        points.s3,
        points.s4,
        points.s5,
        points.s6,
      ],
    });

    // console.log(updateBioResp)
  };

  const nextSlide = () => {
    if (queSlide === slideNum) {
      showErrorCard("Thank you for answering the questions! We appreciate your time and input.", "success");
      setTimeout(() => {
        navigate("/dashboard");
        setActive("dashboard");
        // window.location.href = (window.location.href).split('/').slice(0, 3).join('/')+"/dashboard";
      }, 1000);
      return;
    }
    // if(queSlide == 1){
    //   return
    // }
    if (queSlide == 1) {
      const newBio = {
        panelData: [],
        bio: bio,
      };

      // console.log(newBio);
      patchData("/profile/userProfile", newBio);
    }

    setQueSlide(queSlide + 1);
    // getCurrentQuestions(queSlide + 1 - 2);
    // console.log(queSlide);
    setCurrSlideNum(queSlide);
    updateData();
  };

  const prevSlide = () => {
    if (currSlide == 0) {
      return;
    }
    setQueSlide(queSlide - 1);
    // getCurrentQuestions(queSlide - 1 - 2);
    // console.log(queSlide);
    setCurrSlideNum(queSlide - 2);
    //   setCurrSlideNum(queSlide);
  };

  // const getCurrentQuestions = (currSlide) => {
  //   const a = currSlide * quePerPage;
  //   const b = a + quePerPage;
  //   const currentQuestionArr = questionList.slice(a, b);
  //   setCurrQuestionList(currentQuestionArr);
  //   // console.log(currentQuestionArr);
  // };

  const showOptionList = async (e) => {
    let optionListOverlay =
      e.target.parentNode.querySelector(".optionListOverlay");
    optionListOverlay.style.transform = "scale(1)";

    let options = e.target.parentNode.querySelectorAll(
      ".multiOptions[selected=true]"
    );

    // console.log("all options", e.target.parentNode);

    options.forEach((elem) => {
      elem.click();
    });

    let qLabel = e.target.getAttribute("qLabel");

    try {
      // const token = localStorage.getItem("accessToken");
      // const userResponse = await myApi.get("/profile/userProfile", {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      // let val = getOptionNum(qLabel, userResponse.data.result.panelData);
      let val = getOptionNum(qLabel, userProfileData.result.panelData);
      // console.log(val);
      let allOptions = document.querySelectorAll(".multiOptions");
      allOptions.forEach(async (elem) => {
        if (val.includes(parseInt(elem.getAttribute("value")))) {
          elem.click();
          if (elem.getAttribute("type") === "open_end") {
            let input = elem.querySelector("input");
            const openEndVal = await getOpenEndValue(
              elem.getAttribute("qLabel")
            );
            input.value = openEndVal;
          }
        }
      });
    } catch (error) {
      // console.log(error);
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }
  };

  const hideOptionList = (e) => {
    let optionListOverlay = e.target.parentNode.parentNode;
    optionListOverlay.style.transform = "scale(0)";
  };

  const changeMultipleOptions = async (e, qLabel) => {
    setLoading(true)
    let multiOptions = e.target.parentNode.querySelectorAll(
      ".multiOptions[selected=true]"
    );
    // let allMultiOptions = document.querySelectorAll(".multiOptions");
    let openText = "";
    if (multiOptions.length > 0) {
      // console.log(e.target)
      // let qLabel = e.target.getAttribute("qLabel");
      let valArr = [];
      let openEnd = false;
      multiOptions.forEach((elem) => {
        valArr.push(parseInt(elem.getAttribute("value")));
        if (elem.getAttribute("type") === "open_end") {
          // console.log("openEnd ", elem);
          openText = elem.querySelector("input").value;
          openEnd = true;
        }
      });

      if (openText.trim() === "" && openEnd) {
        showErrorCard("Please enter something in other.", "error");
        setLoading(false)
        return;
      }
      e.target.parentNode.style.transform = "scale(0)";

      let newData;
      if (openEnd) newData = await selectionChange(qLabel, valArr, openText);
      else newData = await selectionChange(qLabel, valArr);
      const newUserData =await getNewUserData()
      await changeBtnText(newUserData.result.panelData);
    }

    if (multiOptions.length == 0) {
      showErrorCard("Please select atleast one option.", "error");
    }
    setLoading(false)
  };

  const getNewUserData = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      const userResponse = await myApi.get("/profile/userProfile", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserProfileData(userResponse.data)
      setCurrentUser(userResponse.data)
      return userResponse.data;
    } catch (err) {
      if (localStorage.getItem("refreshToken") !== null) {
        await renewAccessToken();
      }
    }finally{
      // setLoading(false)
     

    }
  };

  const getQuestionState = (qLabel) => {
    const data = currentUser.result.panelData;
    return data[qLabel]?.status;
  };

  const getMultipleOptionText = (qLabel) => {
    return "Please Select";
  };

  const changeOptionStatus = (e, optionVal) => {
    // if(e.target.classList.contains("openEnd")) return;
    const exclusiveCode = [99, 96];
    let elem = e.target;
    if (!e.target.classList.contains("multiOptions"))
      elem = e.target.parentNode;
    if (
      exclusiveValues.includes(elem.getAttribute("type")) &&
      elem.hasAttribute("selected")
    ) {
      // alert("first")
      deselectOption(elem);
    } else if (exclusiveValues.includes(elem.getAttribute("type"))) {
      // alert("second")
      const allOptions =
        elem.parentNode.parentNode.querySelectorAll(".multiOptions");
      for (let i = 0; i < allOptions.length; i++) {
        deselectOption(allOptions[i]);
      }
      selectOption(elem);
    } else if (elem.hasAttribute("selected")) {
      // alert("third")
      deselectOption(elem);
    } else {
      selectOption(elem);
      // console.log(elem)
      const allOptions =
        elem.parentNode.parentNode.querySelectorAll(".multiOptions");
      // console.log(allOptions);
      for (let i = 0; i < allOptions.length; i++) {
        // console.log(allOptions[i]);
        if (exclusiveValues.includes(allOptions[i].getAttribute("type"))) {
          deselectOption(allOptions[i]);
        }
      }
    }
  };

  const selectOption = (elem) => {
    elem.setAttribute("selected", true);
    elem.style.background = "var(--iconCol)";
    elem.style.color = "white";
    let icon = elem.children[0];
    let optionVal = elem.getAttribute("type");
    if (exclusiveValues.includes(optionVal)) {
      icon.setAttribute("class", "fa-solid fa-circle-check");
    } else {
      icon.setAttribute("class", "fa-solid fa-square-check");
    }
  };

  const deselectOption = (elem) => {
    elem.removeAttribute("selected");
    elem.style.background = "none";
    elem.style.color = "revert";
    let icon = elem.children[0];
    let optionVal = elem.getAttribute("type");

    if (exclusiveValues.includes(optionVal)) {
      icon.setAttribute("class", "fa-regular fa-circle");
    } else {
      icon.setAttribute("class", "fa-regular fa-square");
    }
  };

  const updateData = () => {
    const dataArr = [];
    let question = document.querySelectorAll(".question");
    for (let i = 0; i < question.length; i++) {
      let qLabel = question[i].getAttribute("qLabel");
      let allMultiOptions = document.querySelectorAll(
        '.multiOptions[selected="true"]'
      );
      for (let j = 0; j < allMultiOptions.length; j++) {
        let value = allMultiOptions[j].getAttribute("value");
        let optionArr = [...value];
      }
    }
  };

  const onFileUpload = (e) => {
    e.preventDefault();
    let files;

    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const MAX_FILE_SIZE = 1 * 1024 * 1024;
    if (files[0]?.size > MAX_FILE_SIZE) {
      return showErrorCard("File should not be greater than 5MB", "error");
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const saveImgToDB = (canvas) => {
    // console.log(canvas);
    const token = localStorage.getItem("accessToken");
    const formData = new FormData();

    // Convert the canvas to a Blob
    canvas.toBlob((blob) => {
      // Optionally, create a File from the Blob
      const file = new File([blob], "profilePic.png", { type: "image/png" });

      // Append the Blob/File to the FormData
      formData.append("profilePic", file);

      // console.log(file);
      myApi
        .patch("/profile/userProfile", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          showErrorCard("Image Updated Successfully", "success");
        })
        .catch((error) => {
          if (localStorage.getItem("refreshToken") != null) {
            // console.log("Token is expired");
            renewAccessToken();
          } else {
            console.error("Error uploading image:", error);
          }
        });
    }, "image/png");
  };

  const getCropData = () => {
    if (cropperRef.current) {
      const cropper = cropperRef.current.cropper;
      const croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas) {
        setCroppedImage(croppedCanvas.toDataURL());
      }
      setSelectedImage(croppedCanvas.toDataURL());
      let userImg = document.querySelectorAll(".userImgCont img");
      userImg.forEach((elem) => {
        elem.setAttribute("src", croppedCanvas.toDataURL());
      });
      saveImgToDB(croppedCanvas);
      setImage(null);
      setUploadDis(false);
    }
  };


  const isPinCodeCorrect = async (pincode) => {
    const newData = await getNewUserData();
    // const stateID = newData.result.panelData.State.option[0];
    const pinResp = await getData(
      `/location/PincodeList?V=0&pincode=${pincode}`
    );
    console.log(pinResp)
    console.log(pinResp.data.result.Message)
    if (pinResp.data.result.Message) {
      // console.log(pinResp.data.Message)
      return false;
    } else {
      return true;
    }
  };


  const getNewDataResToPin = async ()=>{
    setLoading(true);
    let pageHeadData =  await getPageHeader();
     let newData;
         const token = localStorage.getItem("accessToken");
         let country = await currCountry();
         let language = await currLang();
         let response = await myApi.get(
             `/panel/panelVaribale/${country}?lang=${language}`,
             {
               headers: {
                 Authorization: `Bearer ${token}`,
               },
             }
              )
             newData = response.data.QuestionList;
         setAllQuestions(newData);
       
   
 
 
   let list =  getCurrentPageQuestions(pageHeadData,newData);
    setCurrQuestionList(list)
    getNewUserData()
    setLoading(false)
  }


  const modifyAllDataForLocation = async ()=>{
    setLoading(true)
      let country = await currCountry();
      let language = await currLang();
      let newLocationData = await getData(`/panel/panelVaribale/${country}?lang=${language}&Page=${currSlide+1}`);
      let questions = newLocationData.data.QuestionList;
      setFinalQuestionList(questions)
      setAllQuestions((prev) => ({
        ...prev,
        ...questions
      }));
      setLoading(false)
  }

  const handlePincode = async (e, m) => {
    if (e.target.value.length > 6) {
        showErrorCard("Pincode should be of 6 digits", "error");
        e.target.value = "";
        setPinError(false);
        return;
    }
    if(e.target.value.length===6){
      console.log(e.target.value)
      setLoading(true);
      const pinCodeStatus = await isPinCodeCorrect(e.target.value);
      if (pinCodeStatus) {
        setPinError(false);
        await selectionChange(m.title, [parseInt(e.target.value)]);
      } else {
        setPinError(true);
        await selectionChange(m.title, [parseInt(e.target.value)]);
      }
        
      // await resetLocationParams()
      let allSelects = document.querySelectorAll(".Single");
      for(let i=0;i<allSelects.length;i++){
        allSelects[i].value = 0;
      }
      await modifyAllDataForLocation()

      // await getNewUserData()
      // getNewDataResToPin()

      // console.log(allQuestions)

     
    }
  
   
  };

  // if (currentUser)
    return (
      // <div className="panDashboard dfr">
      //   <DashboardSidebar active="dashboard" />
      //   <div className="right">
      //     <DashboardTop head="Your Profile" />
      //     <div className="mainCont dfr">
      <>
        {loading && <Loader />}

        <div
          className="left"
          style={{ width: "95%", height: "100%", boxShadow: "none" }}
        >
          <div className="profileTop flex">
            <div
              className="category"
              style={{ color: darkTheme && "var(--darkText)" }}
            >
              {pageHead}
              {currSlide !== 0 && (
                <img
                  style={{ maxHeight: "30px" }}
                  src={pageHeadList[currSlide]?.icon}
                  alt=""
                />
              )}
            </div>
          </div>

          <div className="mainContPar profile">
            {queSlide === 1 && (
              <div className="mainCont">
                <div className="imgCont">
                  <div
                    className="imgDiv"
                    onMouseOver={() => setUploadDis(true)}
                    onMouseLeave={() => setUploadDis(false)}
                  >
                    {currentUser &&   <CircularImageWithProgress
                      src={selectedImage}
                      // size={145}
                      size={circleSize}
                      progress={currentUser?.result?.profilePercent}
                      borderWidth={6}
                    />}
                  

                    <div
                      className="iconCont"
                      style={{ opacity: uploadDis ? 1 : 0 }}
                    >
                      <i
                        className="fa-solid fa-camera editBtn"
                        onClick={() =>
                          document.querySelector(".uploadPicBtn").click()
                        }
                      ></i>
                      <div className="dfc" style={{marginTop:'80%',color:'white'}}> Profile Completion 
                        <br /><div>{currentUser?.result?.profilePercent} %</div> 
                      </div>
                    </div>

                    <input
                      className="uploadPicBtn"
                      accept="image/*"
                      style={{ display: "none" }}
                      type="file"
                      onChange={onFileUpload}
                    />
                  </div>

                  <div
                    className="userName"
                    style={{ color: darkTheme && "var(--darkText)" }}
                  >
                    {currentUser?.result?.name}
                  </div>
                  <div
                    className="profileIntro"
                    style={{
                      color: darkTheme && "var(--darkText)",
                      border: darkTheme && "1px solid var(--darkText)",
                    }}
                  >
                    {bio}
                  </div>
                  <div
                    className="fc"
                    onClick={() => setPerSliderDis(!perSliderDis)}
                    style={{
                      height: "10%",
                      minWidth: "100%",
                      alignItems: "start",
                    }}
                  >
                    <div
                      style={{
                        color: darkTheme && "var(--darkText)",
                        cursor: "pointer",
                        marginTop: "2%",
                        width: "84%",
                      }}
                    >
                      <span style={{ color: "var(--iconCol)" }}>
                        Click here
                      </span>{" "}
                      to generate personality statement by AI based personality
                      slider
                    </div>
                    <div
                      className="perSliderInfo"
                      style={{ display: personInfo ? "block" : "none" }}
                    >
                      Please fill personality slider to generate personality
                      statement
                    </div>
                  </div>
                </div>

                <div className="basicInfo">
                  <div style={{ color: darkTheme && "var(--darkText)" }}>
                    Basic Information
                  </div>

                  <div
                    className="infoCont"
                    style={{
                      color: darkTheme && "var(--darkText)",
                      border: darkTheme && "1px solid var(--darkText)",
                    }}
                  >
                    {Object.entries(finalQuestionList).map(([key, m]) => {
                      return (
                        <div className="infoField">
                          <label htmlFor="">
                            {m.Question_Subtitle_translation}
                          </label>
                          {m.variabelType === "Date" && (
                            <input
                              type="text"
                              qLabel={m.title}
                              disabled={!m.editable}
                              className="dataField"
                            />
                          )}
                          {m.variabelType === "numeric" && (
                            <input
                              type="number"
                              className="dataField"
                              disabled={!m.editable}
                              qLabel={m.title}
                              minLength={6}
                              maxLength={6}
                              onBlur={(e) =>
                                changeBasicInfo(
                                  m.title,
                                  m.variabelType,
                                  parseInt(e.target.value)
                                )
                              }
                            />
                          )}
                          {m.variabelType === "Single" && (
                            <>
                              <select
                                disabled={!m.editable}
                                className={`${m.variabelType} dataField`}
                                // style={
                                //   m.title === "Gender"
                                //     ? { pointerEvents: "none", opacity: 0.6 }
                                //     : {}
                                // }
                                onChange={(e) =>
                                  changeBasicInfo(
                                    m.title,
                                    m.variabelType,
                                    parseInt(e.target.value)
                                  )
                                }
                                name=""
                                id=""
                                qLabel={m.title}
                                qType={m.variabelType}
                              >
                                <option value="0" disabled selected hidden>
                                  Please select
                                </option>
                                {m.option_List?.map((f) => {
                                  return (
                                    <option value={f.id}>{f.translate}</option>
                                  );
                                })}
                              </select>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="personalitySliderCont">
                  <Overlay state={[perSliderDis, setPerSliderDis]}>
                    <div
                      className="closeBtn"
                      onClick={() => setPerSliderDis(false)}
                    >
                      <i class="fa-solid fa-xmark"></i>
                    </div>
                    <div className="sliderStatementCont">
                      <div className="sliderCont">
                        <div className="infoCont">
                          <div
                            style={{
                              color: "var(--iconCol)",
                              fontSize: "1.2em",
                            }}
                          >
                            How would you describe yourself in the following
                            personality attributes? There is no right or wrong
                            answer, please choose the answer towards the
                            attributes you feel more closest with.{" "}
                          </div>
                          <div className="infoField">
                            <div>Introvert</div>
                            <div>Extrovert</div>
                            <div className="circleDiv">
                              {totalPoints.map((m, i) => {
                                return (
                                  <div
                                    onClick={() =>
                                      changeSelectedCircle("s1", i + 1)
                                    }
                                    key={i}
                                    className={
                                      points.s1 === i + 1
                                        ? "circle dark"
                                        : "circle"
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="infoField">
                            <div>Creative</div>
                            <div>Analytical</div>
                            <div className="circleDiv">
                              {totalPoints.map((m, i) => {
                                return (
                                  <div
                                    onClick={() =>
                                      changeSelectedCircle("s2", i + 1)
                                    }
                                    key={i}
                                    className={
                                      points.s2 === i + 1
                                        ? "circle dark"
                                        : "circle"
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="infoField">
                            <div>Messy</div>
                            <div>Organized</div>
                            <div className="circleDiv">
                              {totalPoints.map((m, i) => {
                                return (
                                  <div
                                    onClick={() =>
                                      changeSelectedCircle("s3", i + 1)
                                    }
                                    key={i}
                                    className={
                                      points.s3 === i + 1
                                        ? "circle dark"
                                        : "circle"
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>{" "}
                          <div className="infoField">
                            <div>Emotional</div>
                            <div>Rational</div>
                            <div className="circleDiv">
                              {totalPoints.map((m, i) => {
                                return (
                                  <div
                                    onClick={() =>
                                      changeSelectedCircle("s4", i + 1)
                                    }
                                    key={i}
                                    className={
                                      points.s4 === i + 1
                                        ? "circle dark"
                                        : "circle"
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="infoField">
                            <div>Active</div>
                            <div>Passive</div>
                            <div className="circleDiv">
                              {totalPoints.map((m, i) => {
                                return (
                                  <div
                                    onClick={() =>
                                      changeSelectedCircle("s5", i + 1)
                                    }
                                    key={i}
                                    className={
                                      points.s5 === i + 1
                                        ? "circle dark"
                                        : "circle"
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>
                          <div className="infoField">
                            <div>Play Safe</div>
                            <div>Takes Risk</div>
                            <div className="circleDiv">
                              {totalPoints.map((m, i) => {
                                return (
                                  <div
                                    onClick={() =>
                                      changeSelectedCircle("s6", i + 1)
                                    }
                                    key={i}
                                    className={
                                      points.s6 === i + 1
                                        ? "circle dark"
                                        : "circle"
                                    }
                                  ></div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="statementCont">{bio}</div>
                    </div>
                  </Overlay>
                </div>

                <div className="sliderCont sliderIcons">
                  {pageHeadList.map((m, i) => {
                    return (
                      <div
                        // data-aos={i % 2 === 0 ? "fade-right" : "fade-left"}
                        // data-aos-offset={10}
                        onClick={() => {
                          if (currSlide === i) return;
                          setQueSlide(i + 1);
                          setCurrSlideNum(i);
                        }}
                        className={`icon pointer ${
                          i === currSlide ? "active" : ""
                        }`}
                        style={{
                          color: darkTheme && "black",
                          border: darkTheme && "white solid",
                        }}
                      >
                        <div style={{ fontSize: "2em" }}>
                          <img className="headerImage" src={m.icon} alt="" />
                        </div>
                        <div>{m.translate}</div>
                        <div
                          className="headerUpdateCont"
                          style={{ fontSize: "0.6em" }}
                        >
                          <div
                            className="headUpdateTime"
                            style={{
                              color:
                                currentUser?.pageUpdatedTime[m.pageNo][1] &&
                                "var(--outDated)",
                            }}
                          >
                            <div style={{ fontSize: "0.5em" }}>{`${
                              currentUser?.pageUpdatedTime[m.pageNo][0]
                            }`}</div>
                            <div style={{ fontSize: "0.5em" }}>
                              {currentUser?.pageUpdatedTime[m.pageNo][1] && (
                                <span>
                                  Your information is outdated,
                                  <br />
                                  Please update
                                </span>
                              )}
                            </div>
                          </div>
                          <div
                            className={
                              currentUser?.pageUpdatedTime[m.pageNo][1] &&
                              "blink-animation"
                            }
                            style={{
                              color:
                                currentUser?.pageUpdatedTime[m.pageNo][1] &&
                                "var(--outDated)",
                            }}
                          >
                            Updated At
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {queSlide > 1 && (
              <div className="mainCont">
                <div
                  className="newIconCont"
                  style={{ height: "100%", display: "flex", width: "100%" }}
                >
                  <div className="questionsDiv thinScrollbar">
                    {Object.entries(finalQuestionList).map(([key, m]) => {
                      return (
                        <div className="question" qLabel={m.title}>
                          <div
                            qLabel={m.title}
                            className="questionText"
                            style={{
                              // background: getQuestionState(m.title)
                              //   ? "rgb(63,173,138)"
                              //   : "white",
                              // color: getQuestionState(m.title)
                              //   ? "white"
                              //   : "black",
                              color: darkTheme && "white",
                            }}
                          >
                            {m.Question_title}
                          </div>
                          {m.variabelType === "Single" && (
                            <div className={`optionDiv`}>
                              <select
                                className={`${m.variabelType}`}
                                name=""
                                id=""
                                qLabel={m.title}
                                qType={m.variabelType}
                                onChange={(e) => {
                                  selectionChange(m.title, [
                                    parseInt(e.target.value),
                                  ]);
                                  changeQuestionColor(e);
                                  if (m.title == "Child House hold")
                                    dependentQuestionChange(e.target.value);
                                }}
                              >
                              { 
                                <option value="0" selected disabled hidden>
                                  Please select
                                </option> 
                              }
                                {m.option_List?.map((f) => {
                                  return (
                                    <option value={f.id}>{f.translate}</option>
                                  );
                                })}
                              </select>
                            </div>
                          )}

                          {m.variabelType === "Number" && (
                            <>
                            
                              <div
                              
                                className={`optionDiv`}
                              >
                                <input
                                  className={`${m.variabelType} pincode`}
                                  type="text"
                                  qLabel={m.title}
                                  qType={m.variabelType}
                                  onChange={(e) => {
                                    if (m.title.toLowerCase() === "pincode") {
                                      handlePincode(e, m);
                                    } else {
                                      selectionChange(m.title, [
                                        parseInt(e.target.value),
                                      ]);
                                    }
                                  }}
                                />
                              </div>
                              {pinError && (
                                <div
                                  className="pinError"
                                  onClick={() => setPinDis(true)}
                                >
                                  PIN Code not found in our data base, click
                                  here to inform support if you think pin code
                                  is correct.
                                </div>
                              )}
                              <Overlay state={[pinDis, setPinDis]}>
                                <AddPincode display={setPinDis} />
                              </Overlay>
                              </>
                          
                          )}

                          {m.variabelType === "Multi" && (
                            <div className="optionDiv">
                              <div
                                className={`multiOptBtn ${m.variabelType}`}
                                qlabel={`${m.title}`}
                                onClick={(e) => showOptionList(e)}
                              >
                                {getMultipleOptionText(m.title)}
                              </div>
                              <div className="optionListOverlay">
                                <div className="overlayBtnCont">
                                  <div>{m.Question_title}</div>

                                  {/* <i
                                className="fa-solid fa-check close"
                                onClick={(e) => changeMultipleOptions(e)}
                                style={{ color: "var(--iconCol) !important" }}
                              ></i> */}

                                  <i
                                    className="fa-solid fa-xmark close"
                                    onClick={(e) => hideOptionList(e)}
                                  ></i>
                                </div>

                                <div className="optionList flex">
                                  {isMobile ? (
                                    <>
                                      {m.option_List?.map((f, i) => (
                                        <div
                                          className="flex multiOptions"
                                          value={f.id}
                                          type={f.optionType}
                                          qLabel={m.title}
                                          onClick={(e) =>
                                            changeOptionStatus(
                                              e,
                                              parseInt(f.id)
                                            )
                                          }
                                        >
                                          {exclusiveValues.includes(
                                            f.optionType
                                          ) ? (
                                            <i
                                              style={{ pointerEvents: "none" }}
                                              className="fa-regular fa-circle"
                                            ></i>
                                          ) : (
                                            <i
                                              style={{ pointerEvents: "none" }}
                                              className="fa-regular fa-square"
                                            ></i>
                                          )}
                                          <div
                                            className="listText"
                                            style={{ pointerEvents: "none" }}
                                            value={f.id}
                                          >
                                            {f.title}
                                          </div>
          
                                          {f.optionType == "open_end" && (
                                                  <input
                                                    className="openEnd"
                                                    style={{ marginLeft: "5%" }}
                                                    type="text"
                                                  />
                                                )}
                                        </div>
                                      ))}
                                    </>
                                  ) : (
                                    <>
                                      <div style={{ width: "48%" }}>
                                        {m.option_List?.map(
                                          (f, i) =>
                                            i < m.option_List?.length / 2 && (
                                              <div
                                                className="flex multiOptions"
                                                value={f.id}
                                                type={f.variabelType}
                                                qLabel={m.title}
                                                onClick={(e) =>
                                                  changeOptionStatus(
                                                    e,
                                                    parseInt(f.id)
                                                  )
                                                }
                                              >
                                                {exclusiveValues.includes(
                                                  f.optionType
                                                ) ? (
                                                  <i
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                    className="fa-regular fa-circle"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                    className="fa-regular fa-square"
                                                  ></i>
                                                )}
                                                <div
                                                  className="listText"
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                  value={f.id}
                                                >
                                                  {f.title}
                                                </div>
                                               
                                              </div>
                                            )
                                        )}
                                      </div>

                                      <div style={{ width: "48%" }}>
                                        {m.option_List?.map(
                                          (f, i) =>
                                            i >= m.option_List?.length / 2 && (
                                              <div
                                                className="flex multiOptions"
                                                value={f.id}
                                                type={f.optionType}
                                                qLabel={m.title}
                                                onClick={(e) =>
                                                  changeOptionStatus(
                                                    e,
                                                    parseInt(f.id)
                                                  )
                                                }
                                              >
                                                {exclusiveValues.includes(
                                                  f.optionType
                                                ) ? (
                                                  <i
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                    className="fa-regular fa-circle"
                                                  ></i>
                                                ) : (
                                                  <i
                                                    style={{
                                                      pointerEvents: "none",
                                                    }}
                                                    className="fa-regular fa-square"
                                                  ></i>
                                                )}

                                                <div
                                                  className="listText"
                                                  style={{
                                                    pointerEvents: "none",
                                                  }}
                                                  value={f.id}
                                                >
                                                  {f.title} 
                                                </div>
                                                {f.optionType == "open_end" && (
                                                  <input
                                                    className="openEnd"
                                                    style={{ marginLeft: "5%" }}
                                                    type="text"
                                                  />
                                                )}
                                              </div>
                                            )
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                                <div
                                  className="saveBtn"
                                  onClick={(e) =>
                                    changeMultipleOptions(e, m.title)
                                  }
                                >
                                  Done
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>

                  <div
                    className="sliderCont sliderIcons"
                    style={{ width: "55%" }}
                  >
                    {pageHeadList.map((m, i) => {
                      return (
                        <div
                          onClick={() => {
                            if (currSlide === i) return;
                            setQueSlide(i + 1);
                            setCurrSlideNum(i);
                          }}
                          className={`icon pointer ${
                            i === currSlide ? "active" : ""
                          }`}
                          style={{
                            color: darkTheme && "black",
                            border: darkTheme && "white solid",
                          }}
                        >
                          <div style={{ fontSize: "2em" }}>
                            <img className="headerImage" src={m.icon} alt="" />
                          </div>
                          <div>{m.translate}</div>
                          <div
                            className="headerUpdateCont"
                            style={{ fontSize: "0.6em" }}
                          >
                            <div
                              className="headUpdateTime"
                              style={{
                                color:
                                  currentUser?.pageUpdatedTime[m.pageNo][1] &&
                                  "var(--outDated)",
                              }}
                            >
                              <div style={{ fontSize: "0.5em" }}>{`${
                                currentUser?.pageUpdatedTime[m.pageNo][0]
                              }`}</div>
                              <div style={{ fontSize: "0.5em" }}>
                                {currentUser?.pageUpdatedTime[m.pageNo][1] && (
                                  <span>
                                    Your information is outdated,
                                    <br />
                                    Please update
                                  </span>
                                )}
                              </div>
                            </div>
                            <div
                              className={
                                currentUser.pageUpdatedTime[m.pageNo][1] &&
                                "blink-animation"
                              }
                              style={{
                                color:
                                  currentUser.pageUpdatedTime[m.pageNo][1] &&
                                  "var(--outDated)",
                              }}
                            >
                              Updated At
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}

            <div
              className="flex updateBtnCont"
              style={{ justifyContent: "space-around", width: "18%" }}
            >
              <div
                style={{ visibility: queSlide == 1 ? "hidden" : "visible" }}
                className="updateBtn proUpdate flex"
                onClick={() => prevSlide()}
              >
                <div className="iconDiv dfc">
                  {" "}
                  <i className="fa-solid fa-backward"></i>
                </div>
              </div>

              <div
                style={{
                  visibility: queSlide <= slideNum ? "visible" : "hidden",
                }}
                className="updateBtn proUpdate flex"
                onClick={() => nextSlide()}
              >
                <div className="iconDiv dfc">
                  {" "}
                  <i className="fa-solid fa-forward"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {image && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              left: 0,
              top: 0,
              zIndex: 100,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0,0,0,0.5)",
            }}
          >
            <Cropper
              src={image}
              style={{ height: "50%", width: "50%" }}
              className="imageCropper"
              initialAspectRatio={1}
              guides={false}
              ref={cropperRef}
            />
            <div
              className="cropperBtnDiv"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                columnGap: "2%",
              }}
            >
              <button
                style={{
                  marginTop: "2%",
                  cursor: "pointer",
                  background: "var(--iconCol)",
                  color: "white",
                  padding: "0.5% 2%",
                  borderRadius: "0.3rem",
                  outline: "none",
                  border: "none",
                }}
                onClick={getCropData}
              >
                Crop Image
              </button>

              <button
                style={{
                  marginTop: "2%",
                  cursor: "pointer",
                  background: "indianRed",
                  color: "white",
                  padding: "0.5% 2%",
                  borderRadius: "0.3rem",
                  outline: "none",
                  border: "none",
                }}
                onClick={() => setImage(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </>
    );
}

export default ProfileNew;
