import React, { useEffect, useState } from 'react'
import TrendingSurvey from './TrendingSurvey'
import DashboardTop from './DashboardTop'
import DashboardSidebar from './DashboardComp'
import refer from "../images/refer.jpeg"
import "../css/refer.css"
import { getData, showErrorCard } from '../App'
import myApi from './MyApi'


export default function Refer() {
  const [referToken,setReferToken] = useState("");
  const  copyToClipboard = (text) => {
    var textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
  const shareRefreshToken = ()=>{
    const referLink = referToken
    // navigator.clipboard.writeText(referLink);
    copyToClipboard(referLink);
    showErrorCard('Referral link is copied to your clipboard','success')
  }

  const getReferToken = async ()=>{
    const referTokenResp = await getData('/account/referEarn/getCode');
    setReferToken(referTokenResp.data.result['Refer Code']['Share URL'])
  }
  useEffect(()=>{
    getReferToken()
  },[])
  return (
    // <div className="panDashboard dfr">
    // <DashboardSidebar active="dashboard" />
    // <div className="right">
    //   <DashboardTop />
    //   <div className="mainCont dfr">
        <div className="left refer">
            <img src={refer} alt="" />
            <div className='referBtn pointer' onClick={shareRefreshToken}>
            <i class="fa-solid fa-arrow-right-to-bracket"></i>
            Share
            </div>
        </div>

  //       <TrendingSurvey />
  //     </div>
  //   </div>
  // </div>
  )
}
