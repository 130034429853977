import React,{useEffect, useState} from 'react'
import tree from '../images/tree.png'
import { getData, postData, showErrorCard } from '../App';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../css/giveaway.css";
import Overlay from './Overlay';
import { useActiveContext } from './ActiveContext';
import useCurrentUser from './useCurrentUser';

function Bid() {
  const [treeCount,setTreeCount] = useState(1);
  const [totalPoints,setTotalPoints] = useState(17000);
  const [donationList,setDonationList] = useState([{}]);
  const [pointField,setPointField] = useState(0);
  const [overlayDis,setOverlayDis] = useState(false)
  const [donationResult,setDonationResult] = useState("")
  const {darkTheme} = useActiveContext();
  const [currentUser, setCurrentUser] = useCurrentUser();
  // const treePoints = 50;
  const donatePoints = async (id)=>{
  const donationResp = await postData('/redeem/bidding',{
    "id":id,
})

const resp = await getData('/profile/userProfile')

if(donationResp.name==='AxiosError'){
    // setDonationResult(donationResp.response.data.errors)
    showErrorCard(donationResp.response.data.errors,'error')
    return;
}
document.querySelector('.availPoints').innerText = resp.data.result.TotalPoint;
document.querySelector('.redeemedPoint').innerText = resp.data.result.Total_UsedPoint;
  setDonationResult(donationResp.data.result)

  setOverlayDis(true);
  getDonationList()
  }

  const getDonationList = async ()=>{
    const donationListResp = await getData('/redeem/bidding');
    setDonationList(donationListResp.data.result);
  }



  useEffect(()=>{
    getDonationList()
  },[])

  return (
    <div className='left donate'>

    <div className='title' style={{color:darkTheme&&'var(--darkText)'}}>Bid Center</div>

{donationList.length==0?<div className='dfc' style={{fontSize:'1.5em',minHeight:'300px',color:'indianred'}}>There is no product for bidding</div>:<Swiper
modules={[Navigation, Pagination, A11y, Autoplay]}
spaceBetween={50}
slidesPerView={1}
navigation
loop={true}
autoplay={{
    delay: 2500,
    disableOnInteraction: true
}}
pagination={{ clickable: true }}
>




{donationList && donationList.map(m=>{
 return <SwiperSlide>
 <div className='donateCont' style={darkTheme?{background:"var(--darkBg1)",color:"var(--darkText)"}:{}}>
    <div className="imgCont">
      <img src={m.BiddingImage} alt="" />
    </div>


      <>
      <div className="listCont">
        <ul className='greyCol'>
        {m.detailFirstPage && m.detailFirstPage.map(f=>{
          return <li style={{color:darkTheme&&'var(--darkText)'}}>{f}</li>
        })}
        </ul>
      </div>
      <div className="donateBtnCont">
      {!m.is_Bided&&<div className="pointsText">{m.Point} points will be deducted from your account</div>}
       {
        m.is_Bided?<div className="donateBtn">Bidded</div>:<div className="donateBtn" onClick={()=>donatePoints(m.id)}>Bid Now</div>
       }
        
      </div>
     
      </>

    </div>
    
</SwiperSlide>
})}


        
</Swiper>}



   
  <Overlay state={[overlayDis,setOverlayDis]}>
    <div className="donationCard">
  <i class="fa-solid fa-circle-xmark" style={{color: "white"}} onClick={()=>setOverlayDis(false)}></i>
      <div className="content">{donationResult}</div>
    </div>
  </Overlay>

</div>
  )
}

export default Bid