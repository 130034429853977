import React,{useEffect,useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {getData,postData,patchData} from "../App"
import { useActiveContext } from './ActiveContext'

function Orders() {
    const [order,setOrder] = useState([])
    const navigate = useNavigate();
    const {active,setActive,darkTheme} = useActiveContext();

      const getOrderList = async ()=>{
       const orderListResp = await getData("/redeem/shopProduct/Product");
       setOrder(orderListResp.data.OrderedList);
      }
      useEffect(()=>{
        getOrderList();
      },[])
  return (
    <div className='left orders thinScrollbar'>
         <div className="backToStore pointer"  onClick={() => {navigate("/redeem/productStore"); setActive("productStore")}}>
            <i
              className={darkTheme?"fa-solid fa-circle-chevron-left pointer dark":"fa-solid fa-circle-chevron-left pointer"}
             
            ></i>{" "}
            <span className={darkTheme&&'dark'}>Back to product store</span>
          </div>
        <div className={darkTheme?'dark title':'title'}>Your Orders</div>
        <div className="tableCont thinScrollbar">
        <table>
        <tr>
                    <th>Order ID</th>
                    <th>Order Date</th>
                    <th>Status</th>
                    <th>Points</th>
                    {/* <td>Cancel</td> */}
                </tr>
                {order && order.length===1 && <div>There are no order history</div>}
            {order && order.map((m)=>{
                return <tr>
                    <td>{m.orderId}</td>
                    <td>{m.orderDate}</td>
                    <td>{m.orderStatus}</td>
                    <td>{m.PointUsed}</td>
                    {/* <td>Cancel</td> */}
                </tr>
            })}
        </table>
        </div>
       

    </div>
  )
}

export default Orders