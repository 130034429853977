import React, { useState } from "react";
import DashboardSidebar from "./DashboardComp";
import DashboardTop from "./DashboardTop";
import TrendingSurvey from "./TrendingSurvey";
import {useActiveContext} from './ActiveContext'
import "../css/settings.css";

export default function Settings() {
  const {darkTheme,setDarkTheme} = useActiveContext()
  const [profileComp, setProfileComp] = useState(30);
  const [notifications,setNotifications] = useState({
    'desktop':'ON',
    'mobile':'OFF'
  })
  const handleThemeChange = (e)=>{
    // console.log(e.target.value)
    if(e.target.value == "true"){
      setDarkTheme(true)
      localStorage.setItem("theme",'dark')
    }
    else{
      setDarkTheme(false)
      localStorage.setItem("theme",'light')
    }
  }
  const toggleNotification = (device)=>{
      if(device == "desktop"){
        setNotifications(prev=>({
          ...prev,
          'desktop':prev.desktop === 'ON' ? 'OFF':'ON'
        }))
      }
      else if(device == "mobile"){
        setNotifications(prev=>({
          ...prev,
          'mobile':prev.mobile === 'ON' ? 'OFF':'ON'
        }))
      }
  }

  return (

          <div className="left settings">
            {/* <div className="settingsTag">Settings</div> */}
            <div className="settingsCont" style={{background:darkTheme&&"var(--darkBg1)"}}>
            {/* <div className="flex">
              <span style={{color:darkTheme&&"var(--darkText)"}}>Change Language preference</span>
              <select name="" id="">
                <option value="">English</option>
                <option value="">Hindi</option>
              </select>
            </div> */}
            <div className="flex">
              <span  style={{color:darkTheme&&"var(--darkText)"}}>Change Theme</span>
              <select value={darkTheme} id="" onChange={handleThemeChange}>
                <option value="false">Light</option>
                <option value="true">Dark</option>
              </select>
            </div>

            <div className="flex"  style={{color:darkTheme&&"var(--darkText)"}}>Notification Settings</div>
            <div className="toggleBtnCont">
                <span className="btnLabel"  style={{color:darkTheme&&"var(--darkText)"}}>Desktop</span>
                <span className={notifications.desktop==='OFF'?'toggleBtn  notificationOff':'toggleBtn'} onClick={()=>toggleNotification("desktop")}>
                  <div className="text">{notifications.desktop}</div>
                  <div className="circle"></div>
                </span>
            </div>

            <div className="toggleBtnCont">
                <span className="btnLabel"  style={{color:darkTheme&&"var(--darkText)"}}>Mobile</span>
                <span className={notifications.mobile==='OFF'?'toggleBtn  notificationOff':'toggleBtn'} onClick={()=>toggleNotification("mobile")}>
                  <div className="text">{notifications.mobile}</div>
                  <div className="circle"></div>
                </span>
            </div>
            
            </div>
            
          </div>   
  );
}
