import React from 'react'
import buyNowImg from "../images/buyNow.png"
import {useActiveContext} from "./ActiveContext"

function Ewallet() {
    const {darkTheme} = useActiveContext()
  return (
    <div className='left rewardCenter'>
        <div className='title' style={{color:darkTheme&&"var(--darkText)"}}>Transfer to any e-wallet</div>
        <div className='rewardCont' style={{background:darkTheme&&"var(--darkBg1)",color:darkTheme&&"var(--darkText)"}}>
            <div className='listCont'>
                <label htmlFor="">Mode</label>
                <select name="" id=""></select>
            </div>
            <div className='listCont'>
                <label htmlFor="">Points</label>
                <select name="" id=""></select>
            </div>
            <div className='buyNowBtn'>
                <img src={buyNowImg} alt="" />
            </div>
        </div>
    </div>
  )
}

export default Ewallet