import { useEffect, useState } from 'react';
import myApi from './MyApi';

const renewAccessToken = async () => {
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (!storedRefreshToken) {
      // Handle the case where there's no refresh token (user needs to log in again).
      return;
    }
    
    try {
      const refToken = localStorage.getItem('refreshToken')
      const response = await myApi.post('/account/refresh', {
        "refresh": refToken
      });
      // console.log(response)

      const newAccessToken = response.data.access;
      const newRefreshToken = response.data.refresh;

      // Update the access token in local storage
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      
      // console.log(newAccessToken);
      // console.log(newRefreshToken);

      // Update the state or context with the new access token

      // Continue with your original request or operation
      // ...
    } catch (error) {
      console.log('Error renewing access token:', error.message);
      // Handle token renewal failure (e.g., log the user out)
      // ...
    }
  };

export default renewAccessToken
