import React, { useEffect, useRef, useState } from 'react';

const easeInOutQuad = (t) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

const CircularImageWithProgress = ({ src, size = 150, progress = 10, borderWidth = 6 }) => {
  const [strokeDashoffset, setStrokeDashoffset] = useState(0);
  const [animatedProgress, setAnimatedProgress] = useState(0);
  const [showPercentage, setShowPercentage] = useState(true); // State to control visibility
  const animationFrameId = useRef(null);

  const radius = size / 2;
  const outerRadius = radius + borderWidth / 2;
  const center = (size + borderWidth * 2) / 2;
  const circumference = 2 * Math.PI * outerRadius;

  useEffect(() => {
    let startTime;

    const animateProgress = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const elapsedTime = currentTime - startTime;
      const duration = 3000; // 2 seconds for the animation
      const progressRatio = Math.min(elapsedTime / duration, 1); // Ensures it doesn't exceed 1

      // Apply the ease-in-out transition effect
      const easedProgress = easeInOutQuad(progressRatio);

      const progressValue = easedProgress * progress;
      setAnimatedProgress(progressValue);

      const offset = circumference - (progressValue / 100) * circumference;
      setStrokeDashoffset(offset);

      if (progressRatio < 1) {
        animationFrameId.current = requestAnimationFrame(animateProgress);
      }
    };

    // Initially set strokeDashoffset to zero for a smoother transition on page reload
    setStrokeDashoffset(circumference);

    // Delay the start of the animation to avoid any page load flickers
    setTimeout(() => {
      animationFrameId.current = requestAnimationFrame(animateProgress);
    }, 50); // Delay by 50ms

    // Clean up the animation frame on component unmount
    return () => {
      if (animationFrameId.current) cancelAnimationFrame(animationFrameId.current);
    };
  }, [progress, circumference]);

  useEffect(() => {
    // Hide the percentage text after 5 seconds
    const timeoutId = setTimeout(() => {
      setShowPercentage(false);
    }, 5000);

    // Clear the timeout if the component unmounts or updates
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div
      style={{
        position: 'relative',
        width: `${size + borderWidth * 2}px`,
        height: `${size + borderWidth * 2}px`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
        }}
      >
        <svg
          width={size + borderWidth * 2}
          height={size + borderWidth * 2}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
          aria-label={`Progress: ${Math.round(animatedProgress)}`}
        >
          <circle
            stroke="lightgray"
            strokeWidth={borderWidth}
            fill="transparent"
            r={outerRadius}
            cx={center}
            cy={center}
          />
          <circle
            stroke="var(--iconCol, #3498db)"
            strokeWidth={borderWidth}
            fill="transparent"
            r={outerRadius}
            cx={center}
            cy={center}
            strokeDasharray={circumference}
            strokeDashoffset={strokeDashoffset}
            style={{
              // transition: 'stroke 5s ease-in-out',
              transform: 'rotate(-90deg)',
              transformOrigin: '50% 50%',
            }}
          />
        </svg>
        <img
          src={src}
          // alt="Circular"
          style={{
            width: `${size}px`,
            height: `${size}px`,
            objectFit: 'cover',
            borderRadius: '50%',
            position: 'relative',
          }}
        />
        {showPercentage && ( // Conditionally render the percentage
          <div
            className="profileCompPer"
            style={{
              position: 'absolute',
              top: '80%',
              left: '50%',
              height: '40px',
              width: '40px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              transform: 'translate(-50%, -50%)',
              fontSize: '18px',
              fontWeight: 'bold',
              color: 'white',
              borderRadius: '9em',
              padding: '5%',
              background: 'rgba(0,0,0,0.4)',
              backdropFilter: 'blur(2px)',
            }}
          >
            {`${Math.round(animatedProgress)}%`}
          </div>
        )}
      </div>
    </div>
  );
};

export default CircularImageWithProgress;
