import React from "react";
import panLogo from "../images/panelLogo.png";
import flowRibbon from "../images/flowRib.png"
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./Navbar";


export default function Home() {
  const navigate = useNavigate()
  return (
    <div className="panHome dfr">
        <Navbar active='home'/>
      <div className="left">
        {/* <img className="panLogo" src={panLogo} alt="" />
          <div style={{color:'var(--iconCol)',textWrap:'nowrap',marginLeft:'4%',marginTop:'-1%'}}>Every opinion matter</div> */}
        <div className="ribbonCont">

        <img className="panRibbon" src={flowRibbon} alt="" />
        </div>
        
      </div>
      <div className="right">
       

        <div className="welTag"  to="/about">Welcome to 5th Poll - Where Your Opinions Earn You Rewards!</div>
        <div className="join">Join us and get <br />exciting  rewards!</div>
  <br />
        <div className="joinBtnCont"> <a className="joinBtn pointer"  onClick={()=>navigate('/signup')}>Click To Join Us <i className="fa-solid fa-arrow-right"></i></a></div>
        {/* <div className="gssTag"><span style={{fontSize:'0.5em'}}>By: &nbsp; </span> Global Survey Solutions</div> */}
      </div>
    </div>
  );
}


