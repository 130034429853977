import React from 'react'
import logo from "../images/panelLogo.png"
import darkLogo from "../images/panelLogoDark.png"
import { useActiveContext } from './ActiveContext'

function SurveyCard(props) {
  const {darkTheme} = useActiveContext()
  return (
    <div className='surveyCard'  style={{transform:props.state.surveyCardDis?"scale(1)":"scale(0)",background:darkTheme&&'var(--darkBg1)'}}>
        <div className="surveyCardTop">
            <div className="logo" style={{background:darkTheme&&'var(--darkBg1)'}}>
                <img src={darkTheme?darkLogo:logo} alt="" />
            </div>
        </div>
        <div className='surTitle' style={{color:darkTheme&&'var(--darkText)'}}>{props.title}</div>
        <div className='surDetails' style={{color:darkTheme&&'var(--darkText)'}}>{props.body}</div>
        <a href={props.link} className='startBtn'>
           Start Survey
        </a>
    </div>
  )
}

export default SurveyCard