const searchListArr = [
    {
        "path":"/profile",
        "searchKey":"Update your profile",
        "keywords":['update','profile','complete','change photo','pic','profile picture',"Profile settings",
        "Edit profile",
        "Personal information update",
        "User profile management",
        "Account settings",
        "Modify profile details",
        "Update user information",
        "Profile customization",
        "User account update",
        "Change profile settings"]
    },
    {
        "path":"/redeem",
        "searchKey":'Redeem your points',
        'keywords':[
            "Reward points",
            "Point system",
            "Point accumulation",
            "Points balance",
            "Loyalty points",
            "Point redemption",
            "Earning points",
            "Point rewards",
            "Points program",
            "Point value",
            "Redeem","Point"
        ]
    },
    {
        "path":"/myAccount",
        "searchKey":"Change your password",
        "keywords":[
            "Reset password",
            "Password modification",
            "Update password",
            "Change login password",
            "Password change request",
            "Password security",
            "Modify password",
            "Change account password",
            "New password setup",
            "Password update",
            "Change","pass"
        ]
        
    },
    {
        "path":"/myAccount",
        "searchKey":"Add new phone number",
        "keywords":[
           "add","phone","new phone","mobile","number"
        ]
        
    },
    {
        "path":"/settings",
        "searchKey":"Change language",
        "keywords":[
            "lang",
            "Language settings",
            "Switch language",
            "Update language",
            "Choose language",
            "Language preferences",
            "Modify language",
            "Set language",
            "Adjust language",
            "Language options",
            "Language selection",
            "Change"
        ]
    },
    {
        "path":"/settings",
        "searchKey":"Change theme",
        "keywords": [
            "Theme settings",
            "Switch theme",
            "Update theme",
            "Choose theme",
            "Theme preferences",
            "Modify theme",
            "Set theme",
            "Adjust theme",
            "Theme options",
            "Theme selection","Change"]
    },

    {
        "path":"/settings",
        "searchKey":"Change Notification",
        "keywords": [
            "Notification preferences",
            "Notification settings",
            "Update notifications",
            "Adjust notifications",
            "Notification options",
            "Modify notifications",
            "Manage notifications",
            "Turn on/off notifications",
            "Notification controls",
            "Customize notifications"]
    },
    {
        "path":"/message",
        "searchKey":"View Messages",
        "keywords":[
            "Inbox",
            "Chat history",
            "Message archive",
            "Conversation log",
            "Message thread",
            "Messaging history",
            "Message records",
            "Chat log",
            "Communication history",
            "Message transcripts",
            "message"
        ]
    },
    {
        "path":"/dashboard",
        "searchKey":"Dashboard",
        "keywords":[
            "dashboard","home",""
        ]
    },
    {
        "path":"/help",
        "searchKey":"Help and Support",
        "keywords":[
            "help","support","contact","contact us", "Assistance",
            "Customer service",
            "Troubleshooting",
            "Technical support",
            "FAQ",
            "Live chat support",
            "Contact us",
            "Help center",
            "Support tickets",
            "Knowledge base",
            "Self-service options",
            "User guides",
            "Community forums",
            "Feedback and suggestions",
            "Report an issue",
            "Request assistance",
            "Online help",
            "Helpline",
            "Service desk",
            "Resolution center"
        ]
    },
    {
        "path":"/refer",
        "searchKey":"Refer to earn",
        "keywords":[
            "share", "Recommend",
            "Refer",
            "Invite",
            "Share",
            "Earn rewards",
            "Referral program",
            "Recommendation bonus",
            "Invite friends",
            "Share and earn",
            "Referral link",
            "Recommendation code",
            "Tell a friend",
            "Bring a friend",
            "Referral rewards",
            "Referral incentives",
            "Referral benefits",
            "Join and earn",
            "Earn discounts",
            "Invite others",
            "Referral bonus",
            "Refer to earn"
        
        ]
    },
    {
        "path":"/giveaway",
        "searchKey":"Giveaway - Take part in a contest",
        "keywords":["Freebies",
        "Prizes",
        "Win",
        "Contest",
        "Sweepstakes",
        "Raffle",
        "Giveaway",
        "Free stuff",
        "Enter to win",
        "Prize draw",
        "Competition",
        "Giveaway event",
        "Lucky draw",
        "Chance to win",
        "Enter contest",
        "Prize giveaway",
        "Winning opportunity",
        "Reward program",
        "Free gifts",
        "Gift card giveaway",
        "Prize pool",
        "Winning chance",
        "Participate to win",
        "Get free",
        "Claim your prize"]
    },
    {
        "path":"/redeem/productStore",
        "searchKey":"Buy a product by using your point",
        "keywords":["store","product store","buy","product","redeem"]
    },
    {
        "path":"/redeem/donation",
        "searchKey":"Donation",
        "keywords":["donate","donation","plant tree","give points","redeem","Contribute","charity"]
    },
    {
        "path":"/redeem/eWallet",
        "searchKey":"Redeem your points to e-wallet",
        "keywords":[
            "e-wallet","paytm","phonepe","ewallet","wallet",
            "Transfer to e-wallet",
            "Send to e-wallet",
            "Deposit to e-wallet",
            "Add to e-wallet",
            "Top-up e-wallet",
            "Load e-wallet",
            "Funds to e-wallet",
            "E-wallet transfer",
            "E-wallet deposit",
            "E-wallet top-up",
            "E-wallet load",
            "Send funds to e-wallet",
            "Deposit funds to e-wallet",
            "Add funds to e-wallet",
            "Top-up e-wallet account",
            "Load money to e-wallet",
            "E-wallet balance transfer",
            "E-wallet recharge",
            "E-wallet refill",
            "E-wallet payment",
            "E-wallet transaction",
            "Transfer to digital wallet",
            "Send to digital wallet",
            "Deposit to digital wallet",
            "Add to digital wallet",
            "Top-up digital wallet",
            "Load digital wallet",
            "Funds to digital wallet",
            "Digital wallet transfer",
            "Digital wallet deposit",
            "Digital wallet top-up",
            "Digital wallet load",
            "Send funds to digital wallet",
            "Deposit funds to digital wallet",
            "Add funds to digital wallet",
            "Top-up digital wallet account",
            "Load money to digital wallet",
            "Digital wallet balance transfer",
            "Digital wallet recharge",
            "Digital wallet refill",
            "Digital wallet payment",
            "Digital wallet transaction"
          ]
    },
    {
        "path":"/redeem/rewardCenter",
        "searchKey":"Buy a giftcard",
        "keywords":[
            "Buy gift card",
            "Amazon gift card",
  "Purchase gift card",
  "Order gift card",
  "Get gift card",
  "Buy gift voucher",
  "Purchase gift voucher",
  "Order gift voucher",
  "Get gift voucher",
  "Buy e-gift card",
  "Purchase e-gift card",
  "Order e-gift card",
  "Get e-gift card",
  "Buy digital gift card",
  "Purchase digital gift card",
  "Order digital gift card",
  "Get digital gift card",
  "Buy virtual gift card",
  "Purchase virtual gift card",
  "Order virtual gift card",
  "Get virtual gift card",
  "Buy physical gift card",
  "Purchase physical gift card",
  "Order physical gift card",
  "Get physical gift card",
  "Buy prepaid card",
  "Purchase prepaid card",
  "Order prepaid card",
  "Get prepaid card", 
          ]
    },
    {
        "path":"/redeem/bid",
        "searchKey":"Bid Center",
        "keywords":["bid","bid center","auction","contest","prize"]
    },
    {
        "path":"/redeem/productStore/orders",
        "searchKey":"Order History",
        "keywords":["my orders","orders","history","redeem","order history","purchase","buy","ord"]
    }

]



export default searchListArr;