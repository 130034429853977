import React,{useEffect,useState} from 'react'
import { getData,patchData,postData } from '../App';
import Overlay from './Overlay';
import SurveyCard from './SurveyCard';
import { ActiveProvider, useActiveContext } from './ActiveContext';

export default function TrendingSurvey() {
  const [surveys,setSurveys] = useState([]);
  const [currSurvey,setCurrSurvey] = useState({}); 
  const [surveyCardDis,setSurveyCardDis] = useState(false);
  const {darkTheme} = useActiveContext();

  const getSurveys = async ()=>{
    let surveyResp = await getData("/surveyHistory/mailBox");
    if(surveyResp.data === null) getSurveys()
    surveyResp = surveyResp.data.result
    setSurveys(surveyResp);
  }

  const handleSurveyClick = async (survey)=>{
  setCurrSurvey(survey);
  setSurveyCardDis(true)
  if(!survey.is_read){
   await patchData(`panel/ProfileIndia/mailBox/${survey.id}`,{"is_read":true})
    getSurveys();
  }
  flipElement()
  }

    const changeCurrSurvey = (state)=>{
      let currIdx = surveys.findIndex((f)=>f.id === currSurvey.id);
     if(state=="prev"){
      if(currIdx === 0 ) return;
      setCurrSurvey(surveys[currIdx-1])
      flipElement()
    }
    if(state == "next"){
      if(currIdx == surveys.length-1) return;
      setCurrSurvey(surveys[currIdx+1])
      flipElement()
     }
    }
  const  flipElement = () => {
    var element = document.querySelector('.surveyCard');
    // Add the flip class to trigger the animation
    element.classList.add('flip');
    
    // Remove the flip class after the animation completes
    setTimeout(()=>{
      element.classList.remove('flip');
    }, 1000); // Adjust the timeout to match the animation duration
  }
  useEffect(()=>{
    getSurveys();
  },[])
  return (
    <div className="right dfc">
   

    <div className="trendSurCont" style={{background:darkTheme&&"var(--darkBg1)",color:darkTheme&&"white"}}>
      <div className="surveyCont thinScrollbar">
      <div className="dfr tSurveyHead">
      Available Surveys
    </div>
        {surveys && surveys.map((m,i)=>{
          return  <div className="trendingSurvey dfr pointer" onClick={()=>handleSurveyClick(m)} data-aos="slide-left" data-aos-delay={i*100} data-aos-offset="-1000000" >
          <div>
            <div style={{opacity:m.is_read?0.8:1}}>{m.title}</div>
          </div>
            <i
              className="fa-solid fa-circle-chevron-right pointer"
              style={{ color: "#67a97b", fontSize: "1.25em" }}
            ></i>
        </div>
        })}
        {surveys.length===0 && <div className='noSurveyText'>Sorry, there is no matching surveys for you. Thank you for your patience—exciting surveys are on the way!</div>}
       

       

      </div>

      {/* <a className="allSurBtn dfr" href="/">
        Discover All
      </a> */}
    </div>
    <Overlay state={[surveyCardDis,setSurveyCardDis]}>
    <i class="fa-solid fa-chevron-left" onClick={()=>changeCurrSurvey("prev")}></i>
      <SurveyCard title={currSurvey.title} link={currSurvey.link} body={currSurvey.body} state={{surveyCardDis,setSurveyCardDis}}/>
    <i class="fa-solid fa-chevron-right" onClick={()=>changeCurrSurvey("next")}></i>

     </Overlay>
  </div>
  )
}

