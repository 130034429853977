import React,{useState,useEffect} from 'react'
import panLogo from "../images/panelLogo.png";
import flowRibbon from "../images/flowRib.png"
import panFlower from "../images/loader.png"
import myApi from './MyApi';
import Loader from './Loader';
import { Link } from 'react-router-dom';
import docFile from '../terms.docx'
import Logo from './Navbar'


export default function Welcome() {
    const [userName,setUsername] = useState('Prabhat');
    const [email,setEmail] = useState('Prabhat');
    const [isLoading,setIsLoading] = useState();
    useEffect(()=>{
        const user = localStorage.getItem('username');
        setUsername(user);
        setEmail(localStorage.getItem('email'))
    },[])
  return (
    isLoading?
    <Loader errorLoader={true}/>:

      <div className="panHome dfr">
        <div className="left">
          <Logo/>
        {/* <img className="panLogo" src={panLogo} alt="" /> */}
          {/* <div style={{color:'var(--iconCol)',textWrap:'nowrap',marginLeft:'4%',marginTop:'-1%'}}>Every opinion matter</div> */}
          <div className="ribbonCont">
           <img className="panRibbon" src={flowRibbon} alt="" style={{marginTop:'10%'}} />
        </div>
          
        </div>
        <div className="right">
          {/* <div className="navItems dfr">
            <span>Home</span>
            <a href="/about">About</a>
            <a href="/login">Login</a>
          </div> */}
          <div className="welTag">Welcome to 5th Poll - Where Your Opinions Earn You Rewards!</div>
          <div className="join errMessage welcome" style={{width:'80%',fontSize:'2em',marginTop:'5%'}}>
            <div style={{textAlign:'center'}}>Dear <span style={{color:'var(--iconCol)'}}>{userName}</span></div>
           <div style={{fontSize:'0.7em'}}>
            <ul>
              <li>Welcome to the 5th Poll Community!</li>
              <li>Thank you for joining us. We've sent an activation link to your email: {email}. Please check your inbox and follow the instructions to verify your account.</li>
              <li>As a token of appreciation, you’ll receive 50 points upon successful registration.</li>
              <li className='termsAndConditions'>By becoming a member of the 5th Poll Community, you agree to our <a href='/termsAndConditions' target='_blank'>Terms and Conditions.</a> We encourage you to take a moment to review them.</li>
            </ul>
            <div className='regards'>
            <div>Warm Regards,</div>
            <div>5th Poll Team</div>
            </div>
          
           </div>
          </div>
        </div>
      </div>
  )
}
