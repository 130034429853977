import { useState, useEffect } from 'react';

const MOBILE_WIDTH_THRESHOLD = 700; // Adjust this threshold as needed

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH_THRESHOLD);

  const handleResize = () => {
    setIsMobile(window.innerWidth < MOBILE_WIDTH_THRESHOLD);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  return isMobile;
};

export default useIsMobile;
