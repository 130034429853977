import axios from 'axios';

// Create an Axios instance with a base URL

// for localhost

// const clientToken = '58f4a018-bd70-4ada-a856-b39492ebf965'
// const url = `http://192.168.100.20:6500/FifthPoll/api/${clientToken}`


// for public domain

const clientToken = '796bc5e4-364f-4a18-8428-3d9e3d466b14' 
const url = `https://5thpollapiv2.globalsurveysolutions.com/FifthPoll/api/${clientToken}`
// const url = `http://162.240.106.67:9000/FifthPoll/api/${clientToken}`

const myApi = axios.create({
baseURL:url
});


export default myApi;

