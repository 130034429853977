import React, { useEffect, useState } from "react";
import { getData, postData, showErrorCard } from "../App";
import Loader from "./Loader";
import { currLang, currCountry } from "./utils";

function AddPincode({display}) {
  const [openValues, setOpenValues] = useState({
    region: "0",
    division: "0",
    district: "0",
    block: "0",
    area: "0",
  });

  const [stateList, setStateList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [regionList, setRegionList] = useState([]);
  const [divisionList, setDivisionList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [areaList, setAreaList] = useState([]);

  // Fetching state and country list on component mount
  useEffect(() => {
    const getStateAndCountryList = async () => {
      setIsLoading(true);
      try {
        const language = await currLang();
        const [stateListResp, countryResp] = await Promise.all([
          getData(`/location/StateList?lang=${language}`),
          getData(`/location/CountryList?lang=${language}`),
        ]);

        setStateList(stateListResp.data.result);
        setCountryList(countryResp.data.result);
      } catch (error) {
        // console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getStateAndCountryList();
  }, []);

  // Fetching related lists when a state is selected
  const getValuesOfAddPinCode = async (e) => {
    const currState = e.target.value;
    setIsLoading(true);
    try {
      const language = await currLang();
      const url = `/location/PincodeList?lang=${language}&state=${currState}`;
      const resp = await getData(url);

      setRegionList(resp.data.Region || []);
      setDivisionList(resp.data.Division || []);
      setDistrictList(resp.data.District || []);
      setBlockList(resp.data.Block || []);
      setAreaList(resp.data.Area || []);
    } catch (err) {
      // console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  // Handling form submission
  const handleAddPinCode = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    const {
      state,
      region,
      division,
      district,
      block,
      area,
      pincode,
      divisionInput,
      districtInput,
      blockInput,
      areaInput,
    } = e.target;

    let data = {
      Country: "India",
      State: state.value,
      Region: region.value,
      Division: division.value === "Other" ? divisionInput.value : division.value,
      District: district.value === "Other" ? districtInput.value : district.value,
      Block: block.value === "Other" ? blockInput.value : block.value,
      Area: area.value === "Other" ? areaInput.value : area.value,
      Pincode: pincode.value,
    };

    try {
      const addressResp = await postData("/location/UpdateAddress", data);
      if (addressResp.name === "AxiosError") {
        showErrorCard("Pincode not added", "error");
      } else {
        showErrorCard("Pincode successfully added", "success");
        e.target.reset(); // Reset the form on successful submission
        setOpenValues({
          region: "0",
          division: "0",
          district: "0",
          block: "0",
          area: "0",
        });
        display(false)
      }
    } catch (err) {
      // console.log(err);
    } finally {
      setIsLoading(false);
      e.target.reset();
    }
  };

  // Handling changes in dropdowns and inputs
  const handleChange = (event) => {
    const { name, value } = event.target;
    setOpenValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      {isLoading && <Loader />}

      <form className="addPinCode" onSubmit={handleAddPinCode}>
        <div className="title">Your pincode is not in our database fill the form to add your pincode.</div>

        <div className="flex">
          <select name="country" required>
            <option value="0" disabled selected>
              Select your country
            </option>
            {countryList.map((m) => (
              <option value={m.translate} key={m.id}>
                {m.translate}
              </option>
            ))}
          </select>
        </div>

        <div className="flex">
          <select name="state" required onChange={getValuesOfAddPinCode}>
            <option value="0" disabled selected>
              Select your state
            </option>
            {stateList.map((m) => (
              <option key={m.id} value={m.translate}>
                {m.translate}
              </option>
            ))}
          </select>
        </div>

        <div className="flex">
          <select
            name="region"
            required
            value={openValues.region}
            onChange={handleChange}
          >
            <option value="0" disabled>
              Select your region
            </option>
            {regionList.map((m) => (
              <option key={m.id} value={m.translate}>
                {m.translate}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {openValues.region === "Other" && (
            <input
              type="text"
              placeholder="Enter your region"
              name="regionInput"
              required
            />
          )}
        </div>

        <div className="flex">
          <select
            name="division"
            required
            value={openValues.division}
            onChange={handleChange}
          >
            <option value="0" disabled>
              Select your division
            </option>
            {divisionList.map((m) => (
              <option key={m.id} value={m.translate}>
                {m.translate}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {openValues.division === "Other" && (
            <input
              type="text"
              placeholder="Enter your division"
              name="divisionInput"
            />
          )}
        </div>

        <div className="flex">
          <select
            name="district"
            required
            value={openValues.district}
            onChange={handleChange}
          >
            <option value="0" disabled>
              Select your district
            </option>
            {districtList.map((m) => (
              <option key={m.id} value={m.translate}>
                {m.translate}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {openValues.district === "Other" && (
            <input
              type="text"
              placeholder="Enter your district"
              name="districtInput"
              required
            />
          )}
        </div>

        <div className="flex">
          <select
            name="block"
            required
            value={openValues.block}
            onChange={handleChange}
          >
            <option value="0" disabled>
              Select your block
            </option>
            {blockList.map((m) => (
              <option key={m.id} value={m.translate}>
                {m.translate}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {openValues.block === "Other" && (
            <input
              type="text"
              placeholder="Enter your block"
              name="blockInput"
              required
            />
          )}
        </div>

        <div className="flex">
          <select
            name="area"
            required
            value={openValues.area}
            onChange={handleChange}
          >
            <option value="0" disabled>
              Select your area
            </option>
            {areaList.map((m) => (
              <option key={m.id} value={m.translate}>
                {m.translate}
              </option>
            ))}
            <option value="Other">Other</option>
          </select>
          {openValues.area === "Other" && (
            <input type="text" placeholder="Enter your area" name="areaInput" required />
          )}
        </div>

        <div className="flex">
          <input type="text" placeholder="Enter your pincode" name="pincode" required/>
        </div>

        <br />
        <button type="submit">Submit</button>
      </form>
    </>
  );
}

export default AddPincode;
