import React, { useEffect, useState } from "react";
import dashGirlImg from "../images/dashGirl.png";
import points from "../images/points.png";
import refer from "../images/refer.png";
import giveAway from "../images/giveAway.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import DashboardSidebar from "./DashboardComp";
import DashboardTop from "./DashboardTop";
import TrendingSurvey from "./TrendingSurvey";
import deleteIcon from "../images/deleteIcon.png";
import { useActiveContext } from "./ActiveContext";
import { useNavigate } from "react-router-dom";
import { getData, postData, patchData } from "../App";
import useCurrentUser from "./useCurrentUser";

export default function Message() {
  const [profileComp, setProfileComp] = useState(65);
  const { active, setActive, darkTheme } = useActiveContext();
  const navigate = useNavigate();
  const [message, setMessage] = useState([]);
  const [currentUser, setCurrentUser] = useCurrentUser();
  const openMessage = (e, id, isRead) => {
    let messageBody = e.target.parentNode.querySelector(".messageBody");
    messageBody.style.padding = "2%";
    messageBody.style.height = "100%";
  if(window.innerWidth > 700){
    messageBody.style.height = "28%";
  }

    if (isRead === false) {
      updateMessage({
        id:id,
        is_read: true,
      });
    }
  };

  useEffect(() => {
    getMessage();
  }, []);

  useEffect(() => {
    if (currentUser) {
      setProfileComp(currentUser.result.profilePercent);
    }
  }, [currentUser]);

  const handleMessBtnClick = (e)=>{
    let elem = e.target.parentNode.parentNode;
    elem.style.height = "0%"
    elem.style.padding = "0%"

      // e.target.parentNode.parentNode.style.height ="0%",
      // e.target.parentNode.parentNode.style.padding ="0%";
    
  }
  const getMessage = async () => {
    const messageResp = await getData("/surveyHistory/messageBox");
    setMessage(messageResp.data.result);
  };

  const updateMessage = async (data) => {
    const messageResp = await patchData(
      `/surveyHistory/messageBox`,
      data
    );
    getMessage();
  };

    return (
      <div className="left">
        <div className="flex inboxDelete" data-aos="fade-down">
          {/* <div className="inboxText">Inbox</div> */}
          {/* <div className="">Delete</div> */}
        </div>
        <div
          className="thinScrollbar card redeemCard messageCard "
          style={{ minHeight: "30vh", background: darkTheme && "var(--darkBg1)",color:darkTheme && "var(--darkText)"}}
          data-aos="fade-down"
        >

          <div style={{position:'fixed'}}>
          <div className="inboxText">Inbox</div>

          </div>
         
         <div className="messageMainCont" style={{marginTop:'8%'}}>

         {message.filter((f) => f.status === true).length===0 && <div>There are no messages for you</div>}
          {message &&
            message
              .filter((f) => f.status === true)
              .map((m) => {
                return (
                  <div style={{ height: "30%" }} className="flex message">
                    <div className="flex">
                      <div className="pointer" messId={m.id}>
                        <div
                          className="messageTitle"
                          onClick={(e) => openMessage(e, m.id, m.is_read)}
                          style={{
                            fontWeight: m.is_read === false ? "bold" : "normal",
                          }}
                        >
                          {m.title.slice(0,70)}
                        </div>
                        
                        <div className="messageBody" style={darkTheme?{background:"var(--darkBg1)",color:"var(--darkText)"}:{}}>
                          <div>{m.body}</div>
                          <div
                            className="hideMessBtn"
                            onClick={(e) => handleMessBtnClick(e)}
                          >
                            <i className="fa-solid fa-angle-down"></i>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                    <i
                      className="fa-solid fa-trash-can"
                      onClick={() => updateMessage({id:m.id,status: false })}
                      messId={m.id}
                      style={{ color: "#67a97b" }}
                    ></i>
                  </div>
                );
              })}
         </div>
     
        </div>

        <div className={darkTheme ? "miniCardCont darkCard" : "miniCardCont"}>
          <div
            className="miniCard pointer"
            onClick={() => {
              setActive("profile");
              navigate("/profile");
            }}
            style={{ alignItems: "center" }}
            data-aos="fade-right"
          >
            <div
              style={{ cursor: "pointer", alignItems: "center" }}
              className="imgDiv progDiv"
              onClick={() => setActive("profile")}
            >
              <CircularProgressbar
                value={profileComp}
                text={`${profileComp}%`}
              />
            </div>
            <div>
              <div className="bold">Your Profile</div>
              <div className="greyCol">
                Complete to <br /> earn more
              </div>
              <a
                onClick={() => {
                  setActive("profile");
                  navigate("/profile");
                }}
                className="seeDetailsBtn"
              >
                &gt; See Details
              </a>
            </div>
          </div>
          <div
            className="miniCard pointer"
            onClick={() => {
              setActive("redeem");
              navigate("/redeem");
            }}
            data-aos="fade-left" data-aos-offset="10"
          >
            <div className="imgDiv">
              <img src={points} alt="" />
            </div>
            <div>
              <div className="bold">Total Points</div>
              <div className="greyCol">
                Click to <br /> Redeem
              </div>
              <a
                onClick={() => {
                  setActive("redeem");
                  navigate("/redeem");
                }}
                className="seeDetailsBtn"
              >
                &gt; See Details
              </a>
            </div>
          </div>
          <div
            className="miniCard pointer"
            onClick={() => {
              setActive("giveaway");
              navigate("/giveaway");
            }}
            data-aos="fade-right" data-aos-offset="10"
          >
            <div className="imgDiv">
              <img src={giveAway} alt="" />
            </div>
            <div>
              <div className="bold">Give away</div>
              <div className="greyCol">Participate</div>
              <a
                onClick={() => {
                  setActive("giveaway");
                  navigate("/giveaway");
                }}
                className="seeDetailsBtn"
              >
                &gt; See Details
              </a>
            </div>
          </div>
          <div
            className="miniCard pointer"
            onClick={() => {
              setActive("refer");
              navigate("/refer");
            }}
            data-aos="fade-left" data-aos-offset="10"
          >
            <div className="imgDiv">
              <img src={refer} alt="" />
            </div>
            <div>
              <div className="bold">Refer to Earn</div>
              <div className="greyCol">Join</div>
              <a
                onClick={() => {
                  setActive("refer");
                  navigate("/refer");
                }}
                className="seeDetailsBtn"
              >
                &gt; See Details
              </a>
            </div>
          </div>
        </div>
      </div>
    );
}
