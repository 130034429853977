import React from 'react'
import DashboardTop from './DashboardTop'
import DashboardHome from './DashboardHome'
import DashboardSidebar from './DashboardComp'
import TrendingSurvey from './TrendingSurvey'

export default function DashboardMob() {
    return (
        <div className="panDashboard dfr">
          {/* <DashboardSidebar active='dashboard'/> */}
          <div className="right">
           <DashboardTop/>
            <div className="mainCont dfr">
              <DashboardHome/>
              {/* <TrendingSurvey/> */}
            </div>
          </div>
        </div>
      );
}
