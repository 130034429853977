import React, { useEffect, useState } from "react";
// import dashGirlImg from "../images/dashGirl.png";
import giftCard from "../images/giftCard.png";
import eWallet from "../images/eWallet.png";
import donation from "../images/donation.png";
import productStore from "../images/productStore.png";
import { useActiveContext } from './ActiveContext';
import { getData } from "../App";


import "react-circular-progressbar/dist/styles.css";


export default function Redeem() {
  const {active,setActive,darkTheme} = useActiveContext();
  const [rewSumList,setRewSumList] = useState([])
  const [transactionList,setTransactionList] = useState([])
const [currRewList,setCurrRewList] = useState(rewSumList?.slice(0,3))
const [currTransactionList,setCurrTransactionList] = useState(transactionList?.slice(0,3));
  const [listPage,setListPage] = useState({
    rewSumList:1,
    transactionList:1
  })

  const getRewSumList = async ()=>{
    const resp = await getData("/transaction/Transaction");
    setRewSumList(resp.data.Reward_list);
    setCurrRewList(resp.data.Reward_list?.slice(0,3))
  }
  const getTransactionList = async ()=>{
    const resp = await getData("/transaction/Transaction");
    setTransactionList(resp.data.Transaction_list);
    setCurrTransactionList(resp.data.Transaction_list?.slice(0,3))
  }

  useEffect(()=>{
      getRewSumList();
      getTransactionList();
  },[])


  const getMaxPageLength = (array, itemsPerPage) => {
    return Math.ceil(array.length / itemsPerPage);
  }
  const nextPage = (tableName)=>{

    if(tableName==="rewardSummary"){
     
      let currPage = listPage.rewSumList+1;
      const maxPage = getMaxPageLength(rewSumList,3);
      if(currPage>maxPage) return
      setListPage(prev=>({
        ...prev,
        rewSumList : prev.rewSumList+1
      }))

      let newList = paginate(rewSumList,currPage,3);
      setCurrRewList(newList)
    }

    if(tableName==="transaction"){

      let currPage = listPage.transactionList+1;
      const maxPage = getMaxPageLength(transactionList,3);
      if(currPage>maxPage) return
      setListPage(prev=>({
        ...prev,
        transactionList : prev.transactionList+1
      }))

      let newList = paginate(transactionList,currPage,3);
      setCurrTransactionList(newList)
    }
  }

  const  paginate =(list, pageNumber, itemsPerPage)=>{
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return list.slice(startIndex, endIndex);
  }

  const prevPage = (tableName)=>{
    if(tableName==="rewardSummary"){
      if(listPage.rewSumList===1) return
      setListPage(prev=>({
        ...prev,
        rewSumList : prev.rewSumList-1
      }))

      let currPage = listPage.rewSumList-1;
      let newList = paginate(rewSumList,currPage,3);
      setCurrRewList(newList)
    }

    if(tableName==="transaction"){
      if(listPage?.transactionList===1) return
      setListPage(prev=>({
        ...prev,
        transactionList : prev.transactionList-1
      }))

      let currPage = listPage.transactionList-1;
      let newList = paginate(transactionList,currPage,3);
      setCurrTransactionList(newList)
    }
  }

  return (
        <div className="left redeem">

          <h2 style={{color:darkTheme&&'var(--darkText)'}}>Reward Summary</h2>
         {currRewList?.length>0 ?<> <div className="tableCont">
          <table className="rewardTable" style={darkTheme?{background:"var(--darkBg1)",color:"var(--darkText)"}:{}}>
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Title</th>
            {/* <th>Detail</th> */}
            <th>Point</th>
            <th>Mode</th>
            <th>Survey Status</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {currRewList.map((item, index) => (
            <tr key={index}>
              <td>{item.transactionId}</td>
              <td>{item.title}</td>
              {/* <td>{item.detail}</td> */}
              <td>{item.point}</td>
              <td>{item.mode}</td>
              <td>{item.surveyStatus}</td>
              <td>{item.date}</td>
            </tr>
          ))}
        </tbody>
      </table>   
      </div>
      <div className="pageNav dfr">
        <div style={{opacity:listPage.rewSumList===1?"0.4":"1",color:darkTheme&&"var(--darkText)"}} className="navBtn prev" onClick={()=>prevPage("rewardSummary")}>Prev</div>
        <div className="pageNum">{listPage.rewSumList}</div>
        <div style={{opacity:listPage.rewSumList === getMaxPageLength(rewSumList,3)?"0.4":"1",color:darkTheme&&"var(--darkText)"}} className="navBtn next" onClick={()=>nextPage("rewardSummary")}>Next</div>
      </div>
      </>:<>
          <div className="noRewardSumm" style={{color:darkTheme&&'var(--darkText)'}}>
            There is no reward summary in your account
          </div>
      </>

      }

      <h2 style={{color:darkTheme&&'var(--darkText)'}}>Transaction</h2>
      {currTransactionList?.length>0?<>
      <div className="tableCont">
          <table className="rewardTable" style={darkTheme?{background:"var(--darkBg1)",color:"var(--darkText)"}:{}}>
        <thead>
          <tr>
            <th>Transaction Id</th>
            <th>Title</th>
            {/* <th>Detail</th> */}
            <th>Point</th>
            <th>Transaction Type</th>
            <th>Method</th>
            <th>Transaction Status</th>
            <th>Date</th>
      
          </tr>
        </thead>
        <tbody>
          {currTransactionList.map((item, index) => (
            <tr key={index}>
             <td>{item.transactionId}</td>
              <td>{item.title}</td>
              {/* <td>{item.detail}</td> */}
              <td>{item.point}</td>
              <td>{item.transactionType}</td>
              <td>{item.method}</td>
              <td>{item.transactionStatus}</td>
              <td>{item.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      <div className="pageNav dfr">
        <div style={{opacity:listPage.transactionList===1?"0.4":"1",color:darkTheme&&"var(--darkText)"}} className="navBtn prev" onClick={()=>prevPage("transaction")}>Prev</div>
        <div className="pageNum">{listPage.transactionList}</div>
        <div style={{opacity:listPage.transactionList===getMaxPageLength(transactionList,3)?"0.4":"1",color:darkTheme&&"var(--darkText)"}} className="navBtn next" onClick={()=>nextPage("transaction")}>Next</div>
      </div>
      </>:<>
      <div className="noRewardSumm" style={{color:darkTheme&&'var(--darkText)'}}>There is no transactions in your account</div>
      </>
}

        </div>
  );
}
