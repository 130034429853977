import React, { useState, useEffect } from "react";
import giftCard from "../images/giftCard.png";
import eWallet from "../images/eWallet.png";
import donation from "../images/donation.png";
import productStore from "../images/productStore.png";
import availPoints from "../images/pointsEarned.png";
import redeemPoints from "../images/redeemed.png";
import bidIcon from "../images/bid.png";
import { useActiveContext } from './ActiveContext';
import { useNavigate } from "react-router-dom";
import { getData } from "../App";




function RewardSideBar() {
  const { active,setActive,darkTheme } = useActiveContext();
  const navigate = useNavigate();
  const [userPoints, setUserPoints] = useState({
    available: '----',
    redeemed: '----',
  });


  const getUserPoints = async ()=>{
    let pointresp = await getData("/transaction/Transaction");
    if(pointresp === undefined) return;
     await setUserPoints(prev=>({
      redeemed:pointresp.data.Total_UsedPoint,
      available:pointresp.data.TotalPoint,
    }))
  }
  useEffect(()=>{
   getUserPoints()
  },[])

  return (
    <div className="right dfc redeemSidebar">
    <div className="sideBarPointsCont">
      <div className="availablePoints" style={{ color: darkTheme ? "var(--darkText)" : "inherit" }}>
        <img src={availPoints} alt="" />
        <div>Points Available:</div>
        <div className="bold availPoints">{userPoints.available}</div>
      </div>
      <div className="redeemedPoints" style={{ color: darkTheme ? "var(--darkText)" : "inherit" }}>
        <img src={redeemPoints} alt="" />
        <div>Points Redeemed:</div>
        <div className="bold redeemedPoint">{userPoints.redeemed}</div>
      </div>
    </div>
    <div className="greyCol italic">
      * Click on below to redeem your points
    </div>
    <div className="miniCardCont redeemSideBar">
  
      <a
        className="disabled miniCard redeem pointer"
        data-aos="fade-right"
        onClick={() => { setActive("rewardCenter"); navigate('rewardCenter'); }}
        style={{
          background: active === "rewardCenter"
            ? "var(--greenGradient)"
            : darkTheme
              ? "var(--darkBg1)"
              : "white",
          color: active === "rewardCenter" || darkTheme ? "white" : "black"
        }}
      >
        <div className="imgDiv">
          <img src={giftCard} alt="" />
        </div>
        <div>
          <div className="">Buy gift card from your points</div>
          <a href="/redeem" className="seeDetailsBtn"></a>
        </div>
      </a>
  
      <a
        className="miniCard redeem pointer"
        onClick={() => { setActive("donation"); navigate('donation'); }}
        style={{
          background: active === "donation"
            ? "var(--greenGradient)"
            : darkTheme
              ? "var(--darkBg1)"
              : "white",
          color: active === "donation" || darkTheme ? "white" : "black"
        }}
      >
        <div className="imgDiv">
          <img src={donation} alt="" />
        </div>
        <div>
          <div className="">Donate to Transform Lives</div>
          <a href="/redeem" className="seeDetailsBtn"></a>
        </div>
      </a>
  
      <a
        className="miniCard redeem pointer"
        onClick={() => { setActive("productStore"); navigate('productStore'); }}
        style={{
          background: active === "productStore"
            ? "var(--greenGradient)"
            : darkTheme
              ? "var(--darkBg1)"
              : "white",
          color: active === "productStore" || darkTheme ? "white" : "black"
        }}
      >
        <div className="imgDiv">
          <img src={productStore} alt="" />
        </div>
        <div>
          <div className="">Explore the product store</div>
          <a href="/dashboard" className="seeDetailsBtn"></a>
        </div>
      </a>
  
      <a
        className="disabled miniCard redeem pointer"
        onClick={() => { setActive("eWallet"); navigate('eWallet'); }}
        style={{
          background: active === "eWallet"
            ? "var(--greenGradient)"
            : darkTheme
              ? "var(--darkBg1)"
              : "white",
          color: active === "eWallet" || darkTheme ? "white" : "black"
        }}
      >
        <div className="imgDiv">
          <img src={eWallet} alt="" />
        </div>
        <div>
          <div className="">Transfer to any e-wallet</div>
          <a href="/" className="seeDetailsBtn"></a>
        </div>
      </a>
  
      <div
        className="bidIcon"
        onClick={() => { setActive("bid"); navigate('bid'); }}
        style={{
          background: active === "bid"
            ? "var(--greenGradient)"
            : darkTheme
              ? "var(--darkBg1)"
              : "white",
          color: active === "bid" || darkTheme ? "white" : "black"
        }}
      >
        <span>
          <img src={bidIcon} alt="" />
        </span>
        <span className="text">Click to participate</span>
      </div>
  
    </div>
  </div>
  
  );
}

export default RewardSideBar;
