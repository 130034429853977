import React, { useEffect, useState } from 'react'
import ForgotImg from '../images/forgotPassPan.png'
import panLogo from '../images/panelLogo.png'
import myApi from '../components/MyApi'
import {showErrorCard} from '../App'

export default function ChangePass() {
  const handleChangePass = async (e) => {
    e.preventDefault();
    try {
      const bearerToken = localStorage.getItem("accessToken");  // Extract password values from the form
      const password = e.target.pass.value;
      const password2 = e.target.conPass.value;
      
      // Create the data payload for the request
      const data = {
        password,
        password2,
      };
  

      const headers = {
        Authorization: `Bearer ${bearerToken}`,
        'Content-Type': 'application/json',
      };
  

      const response = await myApi.post('UserChangePassword', data, {
        headers,
      }
      );
      showErrorCard("Password Successfully Changed", "success");
      e.target.reset();
    } catch (err) {
      console.error('An error occurred:', err);
      showErrorCard(err.response ? err.response.data.errors.non_field_errors : 'An error occurred', 'error');
    }
  };

    
  return (
    <div className='forgotPan dfr'>
        <div className='left dfr'>
        <img className="panLogo" src={panLogo} alt=''/>
        <div className='forgotFieldCont dfc'>
            <div>Change Password</div>
            <form onSubmit={(e)=>handleChangePass(e)}>
                <input type="password" placeholder='New Password' name='pass' required />
                <br />
                <input type="password" placeholder='Confirm New Password' name="conPass" required />
                <br />
                <button className='forgotBtn'>Submit</button>
            </form>
        </div>
        </div>
        <div className='right dfc'>
            <img src={ForgotImg} alt="" />
        </div>
    </div>
  )
}

