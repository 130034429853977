import React, { useState } from 'react'

function Overlay({children,state}) {
    const handleOverlayClick = (e)=>{
        if(e.target.classList[0] === "overlay"){
          // state.setSurveyCardDis(false)
          (state[1])(false)
        }
    }
  return (
    <div className='overlay' onClick={(e)=>handleOverlayClick(e)} style={{transform:state[0]?"scale(1)":"scale(0)"}}>
        {children}
    </div>
  )
}

export default Overlay