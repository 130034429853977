import React, { useState, useEffect } from "react";
import axios from "axios";
import addToCart from "../images/addToCart.png";
import checkoutImage from "../images/checkout.png";
import { showErrorCard } from "../App";
import logo from "../images/panelLogo.png"
import chip from "../images/chip.png"
import { getData,patchData,postData } from "../App";
import {useNavigate} from 'react-router-dom'
import { useActiveContext } from "./ActiveContext";

function ProductStore() {
  const {active,setActive,darkTheme} = useActiveContext();
  const [productList, setProductList] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [cartPoints, setCartPoints] = useState(0);
  const [cartDis, setCartDis] = useState(false);
  const [checkOutDis,setCheckOutDis] = useState(false);
  const [userPoints,setUserPoints] = useState(1000);
  const [mobNum, setMobNum] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [shippingMob,setShippingMob] = useState("");
  const [shippingAddress,setShippingAddress] = useState("");
  const [orderId,setOrderId] = useState("");
  const [contactDis,setContactDis] = useState({
    number:false,
    address:false,
    checkout:false,
  })
  const navigate = useNavigate();
  
  const handleBuyNow =async ()=>{
    if(!shippingMob) return showErrorCard("Please select mobile number","error"); 
    if(!shippingAddress) return showErrorCard("Please select address","error");
    // console.log(shippingMob.id)
    const data = {
      "phoneNo":shippingMob.id,
      "Address":shippingAddress.id,
      "productList": cartItems
    }
    // console.log(data)
    const checkoutResp = await postData("/redeem/shopProduct/Checkout",data);
    if(checkoutResp.name === "AxiosError") return;
    setOrderId(checkoutResp.data.orderId);
    setContactDis(prev=>({
      ...prev,
      checkout:true
    }))

    // console.log(checkoutResp.data.Points.TotalAvilabelPoint);
    // console.log(checkoutResp.data.Points.TotalUsedPoint)
    // document.querySelector('.availPoints').innerText = checkoutResp.data.Points.TotalAvilabelPoint;
    // document.querySelector('.redeemedPoint').innerText = checkoutResp.data.Points.TotalUsedPoint;

    // Empty your cart 
    setCartItems([])
    setCartPoints(0)
  //   const cartResp = await patchData("panel/ProfileIndia/profilePanelUpdate",{
  //     "eData":{"cart":[]}
  // });
  getProductList()



   
  }
  const getAddresses = async () => {
    const addressResp = await getData("/profile/address");
    let addressArr = [];
    addressResp.data.result.forEach((m)=>{
      let compAdd =  `${m.address.Locality}, ${m.address.District}, ${m.address.Pincode}, ${m.address.State}`;
      addressArr.push({id:m.id,address:compAdd});
    })
    setAddresses(addressArr)
    // console.log(addressArr)
   
  };

  const getPhoneNum = async () => {
    const mobListResp = await getData("/profile/phoneNo");
    if(mobListResp.name==="AxiosError") return;
   let verifiedNum = mobListResp.data.result.filter((f)=>f.is_verified)
   setMobNum(verifiedNum)
  //  console.log(verifiedNum)
  };
  const getProductList = async(url)=>{
      let proListResp = await getData("/redeem/shopProduct/Product");
      proListResp = proListResp.data;
      // console.log(proListResp)
      setProductList(proListResp.result)
      let cartListResp = proListResp.CartItemList
      // console.log(cartListResp)
      setCartList(cartListResp);
      let newArr = [];
      if(cartListResp){
        for (let i = 0; i < cartListResp.length; i++) {
          pushCartItems(
            cartListResp[i].id,
            cartListResp[i].qty,
            proListResp.result,
            newArr
          );
        }
      }
     
  
      let cartProducts = proListResp.CartItemList.filter((f) => f.qty > 0);
      let points = 0;
      cartProducts.forEach((elem) => {
        points += elem.points * elem.qty;
      });
      setCartItems(cartProducts);
      setCartPoints(points);

    
  }
  useEffect(() => {
   
    //   productList: [
    //     {
    //       id: 1,
    //       title: "Fjallraven - Foldsack No. 1 Backpack, Fits 15 Laptops",
    //       image: "https://fakestoreapi.com/img/81fPKd-2AYL._AC_SL1500_.jpg",
    //       points: 120,
    //       stock: 0,
    //     },
    //     {
    //       id: 2,
    //       title: "Mens Casual Premium Slim Fit T-Shirts ",
    //       image:
    //         "https://fakestoreapi.com/img/71-3HjGNDUL._AC_SY879._SX._UX._SY._UY_.jpg",
    //       points: 259,
    //       stock: 10,
    //     },
    //     {
    //       id: 3,
    //       title: "Mens Cotton Jacket",
    //       image: "https://fakestoreapi.com/img/71li-ujtlUL._AC_UX679_.jpg",
    //       points: 500,
    //       stock: 10,
    //     },
    //     {
    //       id: 4,
    //       title: "Mens Casual Slim Fit",
    //       image: "https://fakestoreapi.com/img/71YXzeOuslL._AC_UY879_.jpg",
    //       points: 430,
    //       stock: 10,
    //     },
    //     {
    //       id: 5,
    //       title:
    //         "John Hardy Women's Legends Naga Gold & Silver Dragon Station Chain Bracelet",
    //       image:
    //         "https://fakestoreapi.com/img/71pWzhdJNwL._AC_UL640_QL65_ML3_.jpg",
    //       points: 400,
    //       stock: 10,
    //     },
    //     {
    //       id: 6,
    //       title: "Solid Gold Petite Micropave ",
    //       image:
    //         "https://fakestoreapi.com/img/61sbMiUnoGL._AC_UL640_QL65_ML3_.jpg",
    //       points: 70,
    //       stock: 10,
    //     },
    //     {
    //       id: 7,
    //       title: "White Gold Plated Princess",
    //       image:
    //         "https://fakestoreapi.com/img/71YAIFU48IL._AC_UL640_QL65_ML3_.jpg",
    //       points: 400,
    //       stock: 10,
    //     },
    //     {
    //       id: 8,
    //       title: "Pierced Owl Rose Gold Plated Stainless Steel Double",
    //       image:
    //         "https://fakestoreapi.com/img/51UDEzMJVpL._AC_UL640_QL65_ML3_.jpg",
    //       points: 100,
    //       stock: 10,
    //     },
    //     {
    //       id: 9,
    //       title: "WD 2TB Elements Portable External Hard Drive - USB 3.0 ",
    //       image: "https://fakestoreapi.com/img/61IBBVJvSDL._AC_SY879_.jpg",
    //       points: 203,
    //       stock: 10,
    //     },
    //     {
    //       id: 10,
    //       title: "SanDisk SSD PLUS 1TB Internal SSD - SATA III 6 Gb/s",
    //       image: "https://fakestoreapi.com/img/61U7T1koQqL._AC_SX679_.jpg",
    //       points: 470,
    //       stock: 10,
    //     },
    //     {
    //       id: 11,
    //       title:
    //         "Silicon Power 256GB SSD 3D NAND A55 SLC Cache Performance Boost SATA III 2.5",
    //       image: "https://fakestoreapi.com/img/71kWymZ+c+L._AC_SX679_.jpg",
    //       points: 319,
    //       stock: 10,
    //     },
    //     {
    //       id: 12,
    //       title:
    //         "WD 4TB Gaming Drive Works with Playstation 4 Portable External Hard Drive",
    //       image: "https://fakestoreapi.com/img/61mtL65D4cL._AC_SX679_.jpg",
    //       points: 400,
    //       stock: 10,
    //     },
    //     {
    //       id: 13,
    //       title:
    //         "Acer SB220Q bi 21.5 inches Full HD (1920 x 1080) IPS Ultra-Thin",
    //       image: "https://fakestoreapi.com/img/81QpkIctqPL._AC_SX679_.jpg",
    //       points: 250,
    //       stock: 10,
    //     },
    //     {
    //       id: 14,
    //       title:
    //         "Samsung 49-Inch CHG90 144Hz Curved Gaming Monitor (LC49HG90DMNXZA) – Super Ultrawide Screen QLED ",
    //       image: "https://fakestoreapi.com/img/81Zt42ioCgL._AC_SX679_.jpg",
    //       points: 140,
    //       stock: 10,
    //     },
    //     {
    //       id: 15,
    //       title: "BIYLACLESEN Women's 3-in-1 Snowboard Jacket Winter Coats",
    //       image: "https://fakestoreapi.com/img/51Y5NI-I5jL._AC_UX679_.jpg",
    //       points: 235,
    //       stock: 10,
    //     },
    //     {
    //       id: 16,
    //       title:
    //         "Lock and Love Women's Removable Hooded Faux Leather Moto Biker Jacket",
    //       image: "https://fakestoreapi.com/img/81XH0e8fefL._AC_UY879_.jpg",
    //       points: 340,
    //       stock: 10,
    //     },
    //     {
    //       id: 17,
    //       title: "Rain Jacket Women Windbreaker Striped Climbing Raincoats",
    //       image: "https://fakestoreapi.com/img/71HblAHs5xL._AC_UY879_-2.jpg",
    //       points: 679,
    //       stock: 10,
    //     },
    //     {
    //       id: 18,
    //       title: "MBJ Women's Solid Short Sleeve Boat Neck V ",
    //       image: "https://fakestoreapi.com/img/71z3kpMAYsL._AC_UY879_.jpg",
    //       points: 130,
    //       stock: 10,
    //     },
    //     {
    //       id: 19,
    //       title: "Opna Women's Short Sleeve Moisture",
    //       image: "https://fakestoreapi.com/img/51eg55uWmdL._AC_UX679_.jpg",
    //       points: 146,
    //       stock: 10,
    //     },
    //     {
    //       id: 20,
    //       title: "DANVOUY Womens T Shirt Casual Cotton Short",
    //       image: "https://fakestoreapi.com/img/61pHAEJ4NML._AC_UX679_.jpg",
    //       points: 145,
    //       stock: 10,
    //     },
    //   ],
    // };
    // setProductList(proListResp.productList);

    // const cartListResp = [{id:10,qty:2},{id:4,qty:4},{id:19,qty:1}];
    getProductList();

    getPhoneNum();
    getAddresses();
    getUserPoints();

   
   
  }, []);

  const getUserPoints = async ()=>{
    let pointResp = await getData("/profile/userProfile");
    setUserPoints(pointResp.data.result.TotalPoint);
  }
  const pushCartItems = async (id, qty, proList, arr) => {
    // console.log(proList)
    let currProduct = proList.filter((f) => f.id === id);
    // console.log(currProduct)
    currProduct[0].qty = qty;
    arr.push(currProduct[0]);
    // console.log(arr)
    setCartItems(arr);
  };

  const increaseQuantity = (id, qty, elem) => {
    let currProduct = productList.filter((f) => f.id == id)[0];
    let count = elem.parentNode.querySelector(".proQuan");
    if (currProduct.qty === undefined) {
      // count.innerText = 1;
      currProduct.qty = 1;
    } else if (currProduct.qty < currProduct.stock) {
      if (count) count.innerText = currProduct.qty + 1;
      currProduct.qty = currProduct.qty + 1;
    } else if (currProduct.qty === currProduct.stock) {
      showErrorCard("There is no more stocks of this product", "error");
    }
    updateCartItems();
  };

  const decreaseQuantity = (id, qty, elem) => {
    let count = elem.parentNode.querySelector(".proQuan");
    let currProduct = productList.filter((f) => f.id == id)[0];
    if (currProduct.qty > 0) {
      if (count) count.innerText = currProduct.qty - 1;
      currProduct.qty = currProduct.qty - 1;
    }
    updateCartItems();
  };
  const deleteCartItem = (id) => {
    let currProduct = productList.filter((f) => f.id == id)[0];
    // console.log(currProduct)
    currProduct.qty = 0;
    let cartData = {"productList":[{"id":currProduct.id,"qty":0}]};
    postData('/redeem/shopProduct/StoreProduct',cartData);
    updateCartItems();
  };
  const updateCartItems = () => {

    let cartProducts = productList.filter((f) => f.qty > 0);
    let points = 0;
    cartProducts.forEach((elem) => {
      points += elem.points * elem.qty;
    });
    setCartItems(cartProducts);
    setCartPoints(points);

    let cartArr = [];
    for (let i = 0; i < cartProducts.length; i++) {
      let productObj = {
        id: cartProducts[i].id,
        qty: cartProducts[i].qty,
      };
      cartArr.push(productObj);
    }
    // console.log(cartArr);
    let cartData = {"productList":cartArr};
    postData('/redeem/shopProduct/StoreProduct',cartData);
  };

  const showCart = () => {
    setCartDis(!cartDis);
  };

  const handleCheckOut = ()=>{
    if(userPoints<cartPoints) {
      return showErrorCard("You don't have enough points to buy all items of your cart, please remove some items or earn some more points.","error")
    }else if(cartItems.length===0){
      return showErrorCard("There is no product in your cart","error")
    }
    setCheckOutDis(true)
  }

  if (productList)
    return (
      <div className="left productStore">
        <div className="productStore title"><span style={{color:darkTheme&&'var(--darkText)'}}>Buy Product</span><a onClick={()=>{navigate("productStore/orders");setActive("orders")}} className="orderHisBtn" style={{color:darkTheme&&"var(--darkText)"}}><i className="fa-solid fa-file-waveform"></i> Order History </a></div>
        {productList.length===0?<div className="dfr" style={{height:'78%',color:'indianred',minHeight:'300px',fontSize:'1.5em'}}>There is no product right now</div>:<div className="productCont thinScrollbar">
          
          {productList.map((m) => {
            return (
              <div className="productCard" style={{ border: m.stock < 1 ? "1.2px solid indianred" : "", background: darkTheme && "var(--darkBg1)" }}>
                <div className="proImage">
                  <img src={m.productImage} alt="" />
                </div>
                <div className="proName" style={{color:darkTheme&&"var(--darkText)"}}>{m.title.slice(0, 40)}</div>
                <div className="proPoints greyCol">{m.points} Points</div>
                <div className="addCartBtn dfr">
                  {m.stock > 0 ? (
                    <img
                      className="pointer"
                      src={addToCart}
                      alt=""
                      onClick={(e) => increaseQuantity(m.id, m.qty, e.target)}
                    />
                  ) : (
                    <div className="outOfStock">Out of stock</div>
                  )}

                  {m.qty > 0 && (
                    <div className="qBtnCont dfr">
                      <div
                      style={{color:darkTheme&&"var(--darkText)"}}

                        className="qBtn"
                        onClick={(e) => decreaseQuantity(m.id, m.qty, e.target)}
                      >
                        -
                      </div>
                      <div className="proQuan">{m.qty}</div>
                      <div
                      style={{color:darkTheme&&"var(--darkText)"}}
                        className="qBtn"
                        onClick={(e) => increaseQuantity(m.id, m.qty, e.target)}
                      >
                        +
                      </div>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>}
        {productList.length!==0 &&  <div className="btnCont">
          <div className="addedItems" onClick={() => showCart()}>
            <i class="fa-solid fa-cart-plus"></i>
            <div>
              <div>{cartItems.length} Items</div>
              <div>Points : {cartPoints}</div>
            </div>
          </div>
          <div className="checkOutBtn" onClick={handleCheckOut}>
            <img src={checkoutImage} alt="" />
          </div>
        </div>}
       

        <div className="cart" style={{ height: cartDis ? "87%" : "0%" }}>
          <div className="backToStore pointer" onClick={() => setCartDis(false)}>
            <i
              className="fa-solid fa-circle-chevron-left"
            
            ></i>{" "}
            <span>Back to product store</span>
          </div>
          <div className="productTableCont thinScrollbar">
            {cartItems.length === 0 ? (
              <div className="noProduct">There is no product in your cart.</div>
            ) : (
              <table className="cartTable">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Points</th>
                    <th style={{ width: "10%" }}>Qty</th>
                    <th>Total Points</th>
                    <th></th>
                  </tr>
                </thead>
                {cartItems.map((m) => {
                  return (
                    <tr>
                      <td>
                        <img src={m.productImage} alt="" />
                        <div>{m.title.slice(0, 24)}</div>
                      </td>
                      <td>{m.points}</td>
                      <td>
                        <span
                          className="btn"
                          onClick={(e) =>
                            decreaseQuantity(m.id, m.qty, e.target)
                          }
                        >
                          -
                        </span>
                        <span>{m.qty}</span>
                        <span
                          className="btn"
                          onClick={(e) =>
                            increaseQuantity(m.id, m.qty, e.target)
                          }
                        >
                          +
                        </span>
                      </td>
                      <td>{m.qty * m.points}</td>
                      <td>
                        <i
                          className="fa-solid fa-trash pointer"
                          onClick={(e) => deleteCartItem(m.id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
              </table>
            )}
          </div>
        </div>
        
        <div className="checkOutCont" style={{height:checkOutDis?"100%":"0%"}}>
            
            <div className="cardContainer checkout">
              <div className="card">
                <div className="logo">
                  {/* <img src={logo} alt="" /> */}
                  <div>5th Poll</div>
                </div>
                <div className="chipCont">
                  <img src={chip} alt="" />
                  <i class="fa-solid fa-wifi"></i>
                </div>
                <div className="points">{cartPoints} points will be deducted from your account.</div>
              </div>
              <input onClick={() => setContactDis((prev) => ({
    ...prev,
    number: true
}))} type="number" name="" id="" placeholder="Mobile Number" value={shippingMob.number} />
              {/* <textarea name="" id="" cols="30" rows="10" placeholder="Shipping Address"></textarea> */}
              <input type="text" placeholder="Shipping Address" value={shippingAddress.address}  onClick={() => setContactDis((prev) => ({
    ...prev,
    address: true
}))} />
              <div className="btnCont">
                <button className="buyNow" onClick={handleBuyNow}>Buy Now</button>
                <div className="cancel" onClick={()=>setCheckOutDis(false)}>Cancel</div>
              </div>
            </div>

            {contactDis.number && <div className="mobileNumCont thinScrollbar">
              <div>Select Contact Number</div>
              <i class="fa-solid fa-circle-xmark" onClick={()=>setContactDis(prev=>({
                ...prev,
                number:false
              }))} style={{color: "indianred"}}></i>
                            {mobNum.length===0 && <><div className="errorText">There is no saved mobile number, please mobile number by using my account section.</div>
              <div className="mobCard" onClick={()=>{navigate('/myaccount'); setActive("myAccount")}}>Add mobile num</div></>}
              {mobNum && mobNum.map((m)=>{
                return <div className="mobCard" onClick={()=>{setShippingMob(m)
                setContactDis(prev=>({
                  ...prev,
                  number:false
                }))}
              }>{m.number}</div>
              })}
            </div>}

            {contactDis.address && <div className="mobileNumCont thinScrollbar">
              <div>Select Contact Address</div>
              <i class="fa-solid fa-circle-xmark" onClick={()=>setContactDis(prev=>({
                ...prev,
                address:false
              }))} style={{color: "indianred"}}></i>
              {addresses.length===0 && <><div className="errorText">There is no saved address, please add an address by using my account section.</div>
              <div className="mobCard" onClick={()=>{navigate('/myaccount'); setActive("myAccount")}}>Add address</div></>}
              {addresses && addresses.map((m)=>{
                return <div className="mobCard" onClick={()=>{setShippingAddress(m)
                setContactDis(prev=>({
                  ...prev,
                  address:false
                }))}
              }>{m.address}</div>
              })}
            </div>}

              
            {contactDis.checkout && <div className="mobileNumCont thinScrollbar dfc">
             
              <i class="fa-solid fa-circle-xmark" onClick={()=>{setContactDis(prev=>({
                ...prev,
                checkout:false
              }))
              setCheckOutDis(false)
                navigate('/redeem/productStore/orders')
                setActive('orders')
         
            }
              } style={{color: "indianred"}}></i>
              <div className="tickCont">
             <i class="fa-regular fa-circle-check fa-beat-fade tick" style={{color: "#67a97b"}}></i>
              </div>
              <div>Your order is successfully placed.</div>
              <div>Order id is <span className="bold">{orderId}</span></div>
            </div>}
           
        </div>

      </div>
    );
}

export default ProductStore;
