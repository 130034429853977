import React, { useEffect, useState } from "react";
import "../css/datePicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

function DatePicker({width,value,setValue}) {
  const [display, setDisplay] = useState(false);
  const [dateList,setDateList] = useState([]);
  const [monthNum,setMonthNum] = useState(1);
  const [yearList,setYearList] = useState([]);
  const [year,setYear] = useState();
  const [currDate,setCurrDate] = useState();

  const showDatePicker = () => {
    setDisplay(true);
  };

  const generateCalendar = (year, month)=> {
    month = month-1;
    const daysInWeek = 7;
    const calendar = [];

    // Get the first day of the current month (0 = Sunday, 1 = Monday, etc.)
    let startDayOfMonth = new Date(year, month, 1).getDay();
    
    // Adjust the start day to treat Monday as the first day of the week
    startDayOfMonth = (startDayOfMonth === 0) ? 6 : startDayOfMonth - 1;

    // Get the last date of the previous month
    const prevMonth = month === 0 ? 11 : month - 1; // December if month is January (0)
    const prevYear = month === 0 ? year - 1 : year;
    const daysInPrevMonth = new Date(prevYear, prevMonth + 1, 0).getDate();

    // Get the last date of the current month
    const daysInCurrentMonth = new Date(year, month + 1, 0).getDate();

    // Start filling the calendar with the previous month's dates
    let prevMonthStart = daysInPrevMonth - (startDayOfMonth - 1);

    for (let i = 0; i < startDayOfMonth; i++) {
        calendar.push({
            day: prevMonthStart,
            month: prevMonth + 1, // Adjust month for human-readable form (1-indexed)
            year: prevYear,
            currentMonth: false
        });
        prevMonthStart++;
    }

    // Fill with the current month's dates
    for (let i = 1; i <= daysInCurrentMonth; i++) {
        calendar.push({
            day: i,
            month: month + 1, // Adjust month for human-readable form (1-indexed)
            year: year,
            currentMonth: true
        });
    }

    // Fill the remaining cells with next month's dates if needed
    let nextMonthDay = 1;
    while (calendar.length % daysInWeek !== 0) {
        calendar.push({
            day: nextMonthDay,
            month: month === 11 ? 1 : month + 2, // Adjust for December wrapping to January
            year: month === 11 ? year + 1 : year,
            currentMonth: false
        });
        nextMonthDay++;
    }
    setDateList(calendar);
    console.log(calendar)
}

const formatDate = (dateString)=>{
  const [year, month, day] = dateString.split('-');
  const paddedMonth = month.padStart(2, '0'); // Ensures the month has two digits
  const paddedDay = day.padStart(2, '0');     // Ensures the day has two digits
  return `${year}-${paddedMonth}-${paddedDay}`;
}


useEffect(()=>{
  generateCalendar(year,monthNum);
  // console.log(`${year}-${monthNum}-${currDate}`)
},[year,monthNum,currDate])



const handleDateClick = (date)=>{

  setCurrDate(date);
  setDisplay(false)
  let dateStr = formatDate(`${year}-${monthNum}-${date}`);
  setValue(dateStr)

}
const handleYearChange = (e)=>{
        let selectedYear = e.target.value;
        setYear(selectedYear)
  }

  const changeMonth = (action)=>{
    if(action === 'next'){
        if(monthNum===12) setMonthNum(1)
        else setMonthNum(monthNum+1)
    }

    if(action === 'prev'){
        if(monthNum===1) setMonthNum(12)
        else setMonthNum(monthNum-1)
    }

  }

  const getMonth = ()=>{
    switch(monthNum) {
        case 1: return 'JAN';
        case 2: return 'FEB';
        case 3: return 'MAR';
        case 4: return 'APR';
        case 5: return 'MAY';
        case 6: return 'JUN';
        case 7: return 'JUL';
        case 8: return 'AUG';
        case 9: return 'SEP';
        case 10: return 'OCT';
        case 11: return 'NOV';
        case 12: return 'DEC';
        default: return 'Invalid Month';
      }
  }



  const generateYears = ()=>{
    const currentYear = new Date().getFullYear(); // Get the current year
    setYear(currentYear)
    const years = [];
  
    // Add previous 100 years
    for (let i = 100; i > 0; i--) {
      years.push(currentYear - i);
    }
  
    // Add current year
    years.push(currentYear);
  
    // Add next 50 years
    for (let i = 1; i <= 50; i++) {
      years.push(currentYear + i);
    }
  
    setYearList(years);
  }
  useEffect(()=>{
    generateYears()
    const currentMonthNum = new Date().getMonth() + 1;
    setMonthNum(currentMonthNum)
    const day = new Date().getDate();
    setCurrDate(day)
  },[])


  return (
    <>
      <input type="text" onClick={showDatePicker} value={value} placeholder="YYYY-MM-DD"/>
      <div
        className="pickerCont"
        style={{width:width ||'250px', display: display ? "flex" : "none" }}
      >
        <div className="headCont">

            <div className="month">
            <FontAwesomeIcon icon={faChevronLeft} style={{cursor:'pointer'}} onClick={()=>changeMonth("prev")}/>
                <span className="monthName">
                  {/* {getMonth(monthNum)} */}
                  <select name="" id="" value={monthNum} onChange={(e)=>setMonthNum(parseInt(e.target.value))}>
                    <option value="1">Jan</option>
                    <option value="2">Feb</option>
                    <option value="3">Mar</option>
                    <option value="4">Apr</option>
                    <option value="5">May</option>
                    <option value="6">Jun</option>
                    <option value="7">Jul</option>
                    <option value="8">Aug</option>
                    <option value="9">Sep</option>
                    <option value="10">Oct</option>
                    <option value="11">Nov</option>
                    <option value="12">Dec</option>
                  </select>
                </span>
            <FontAwesomeIcon icon={faChevronRight} style={{cursor:'pointer'}} onClick={()=>changeMonth("next")}/>           
            </div>
            <select className="year" value={year} onChange={handleYearChange}>
                {yearList.map((m)=>{
                    return <option value={m}>{m}</option>
                })}
            </select>
        </div>

        <div className="dateCont">
                    <div className="dateRow">
                   
      {dateList.map((item, index) => (
        <div className={currDate===item.day&&item.currentMonth?"date active":"date"} onClick={()=>handleDateClick(item.day)} key={index} style={!item.currentMonth?{opacity:'0.5',pointerEvents:'none'}:{}}>{item.day}</div>
      ))}
    
                    </div>
                    {/* <div className="dateRow">
                        <div className="date">8</div>
                        <div className="date">9</div>
                        <div className="date">10</div>
                        <div className="date">11</div>
                        <div className="date">12</div>
                        <div className="date">13</div>
                        <div className="date">14</div>
                    </div>
                    <div className="dateRow">
                        <div className="date">15</div>
                        <div className="date">16</div>
                        <div className="date">17</div>
                        <div className="date">18</div>
                        <div className="date">18</div>
                        <div className="date">19</div>
                        <div className="date">20</div>
                    </div>
                    <div className="dateRow">
                        <div className="date">21</div>
                        <div className="date">22</div>
                        <div className="date">23</div>
                        <div className="date">24</div>
                        <div className="date">25</div>
                        <div className="date">26</div>
                        <div className="date">27</div>
                    </div>
                    <div className="dateRow">
                        <div className="date">28</div>
                        <div className="date">29</div>
                        <div className="date">30</div>
                        <div className="date">31</div>
                        <div className="date">1</div>
                        <div className="date">2</div>
                        <div className="date">3</div>
                    </div> */}
                     
        </div>
      </div>
    </>
  );
}

export default DatePicker;
