import React,{useEffect, useState} from 'react'
import tree from '../images/tree.png'
import { getData, postData, showErrorCard } from '../App';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import "../css/giveaway.css";
import Overlay from './Overlay';
import { useActiveContext } from './ActiveContext';
import Loader from './Loader';

function Donation() {
  const [treeCount,setTreeCount] = useState(1);
  const [totalPoints,setTotalPoints] = useState(17000);
  const [donationList,setDonationList] = useState([{}]);
  const [pointField,setPointField] = useState(0);
  const [overlayDis,setOverlayDis] = useState(false)
  const [donationResult,setDonationResult] = useState("")
  const {darkTheme} = useActiveContext()
  const [loading,setLoading] = useState(false);
  // const treePoints = 50;
  const increaseTreeCount = ()=>{
    setTreeCount(treeCount+1)
  }
  const decreaseTreeCount = ()=>{
    if(treeCount > 1){
      setTreeCount(treeCount-1)
    }
  }
  const donatePoints = async (points,minPoints,id)=>{
    
  if(points<minPoints) return showErrorCard(`Minimum points should be ${minPoints}`,'error');
 
  setLoading(true)
  const donationResp = await postData('/redeem/donation',{
    "id":id,
    "Point":points
})


if(donationResp.name==="AxiosError"){
  showErrorCard(donationResp.response.data.errors,'error')
  setLoading(false)
  return
}

const userResp = await getData('/profile/userProfile');

document.querySelector('.availPoints').innerText = userResp.data.result.TotalPoint;
document.querySelector('.redeemedPoint').innerText = donationResp.data.result.Total_UsedPoint;
 
  setDonationResult(donationResp.data.result)
  setOverlayDis(true);
  setPointField(0)
  setTreeCount(1)
  setLoading(false)
  }

  const getDonationList = async ()=>{
    const donationListResp = await getData('/redeem/donation');
    setDonationList(donationListResp.data.result);
   
  }

  useEffect(()=>{
    getDonationList()
  },[])

 
  return (
<>
{loading && <Loader/>}
<div className='left donate'>

    <div className='title' style={{color:darkTheme&&"var(--darkText)"}}>Donations</div>

{donationList.length==0?<div className='dfc' style={{minHeight:'300px',fontSize:'1rem',color:'indianred'}}>There is no donations for you </div>:  <Swiper
modules={[Navigation, Pagination, A11y, Autoplay]}
spaceBetween={50}
slidesPerView={1}
navigation
loop={true}
// autoplay={{
//     delay: 2500,
//     disableOnInteraction: true
// }}
pagination={{ clickable: true }}
>




{donationList && donationList.map(m=>{
 return <SwiperSlide>
 <div className='donateCont' style={darkTheme?{background:"var(--darkBg1)"}:{}}>
    <div className="imgCont">
      <img src={m.DonationImage} alt="" />
    </div>


      <>
      <div className="listCont">
        <ul className='greyCol'>
        {m.detailFirstPage && m.detailFirstPage.map(f=>{
          return <li style={{color:darkTheme&&"var(--darkText)"}}>{f}</li>
        })}
        </ul>
      </div>
      {m.multipleOfPoints? <div className="donateBtnCont">
      {treeCount>0&&<div className="pointsText">{treeCount*m.Point} points will be deducted from your account</div>}
        <div className="rangeBtn dfr">
          
          <div className="btn" onClick={decreaseTreeCount}>-</div>
          <div className="count">{treeCount}</div>
          <div className="btn" onClick={increaseTreeCount}>+</div>
        </div>
       
        <div className="donateBtn" onClick={()=>donatePoints(parseInt(treeCount*m.Point),m.Point,m.id)}>Donate Now</div>
      </div>: <div className="donateBtnCont">
      {treeCount>0&&<div className="pointsText">{pointField} points will be deducted from your account</div>}
        <div className="rangeBtn dfr">
          
          {/* <div className="btn" onClick={decreaseTreeCount}>-</div> */}
          <div className="count">
            <input type="number" value={pointField} onInput={(e)=>setPointField(e.target.value)}/>
          </div>
          {/* <div className="btn" onClick={increaseTreeCount}>+</div> */}
        </div>
       
        <div className="donateBtn" onClick={()=>donatePoints(parseInt(pointField),m.Point,m.id)}>Donate Now</div>
      </div>}
     
      </>

    </div>
    
</SwiperSlide>
})}


</Swiper>}
  


  <Overlay state={[overlayDis,setOverlayDis]}>
    <div className="donationCard">
  <i class="fa-solid fa-circle-xmark" style={{color: "white"}} onClick={()=>setOverlayDis(false)}></i>
      <div className="content">{donationResult}</div>
    </div>
  </Overlay>

</div>
</>

  )
}

export default Donation