import React from 'react'
import Overlay from './Overlay'
import loaderImg from '../images/loader.png'

function Loader(props) {
  return (
    <div className='loader' style={{background:props.errorLoader&&'white'}}>
    <img src={loaderImg} alt="" />
</div>
  )
}

export default Loader