import React, { useState } from "react";
import panLogo from "../images/panelLogo.png";
import myApi from './MyApi';
import { Navigate, useNavigate } from 'react-router-dom';
import DashboardTop from './DashboardTop';
import DashboardComp from "./DashboardComp";
import { useActiveContext } from './ActiveContext';
import DashboardSidebar from "./DashboardSidebar";



export default function Dashboard() {
  const navigate = useNavigate();
  const {active,setActive,darkTheme} = useActiveContext();

  return (
    <div className="panDashboard dfr" style={{background: darkTheme && "var(--darkBg)"}}>
      
      <DashboardSidebar/>
      <div className="right">
       <DashboardTop/>
       <DashboardComp active={active}/>
      </div>

    </div>
  )
}




// <div className="panDashboard dfr">
// <DashboardSidebar active='dashboard'/>
// <div className="right">
//  <DashboardTop/>
//   <div className="mainCont dfr">
//     <DashboardMid/>
//     {/* <Message/> */}
//     {/* <Redeem/> */}
//     {/* <MyAccount/> */}
//     {/* <Settings/> */}
//     {/* <Help/> */}
//     <TrendingSurvey/>
//   </div>
// </div>
// </div>