import React, { Children, useEffect ,useState} from 'react'

import userMale from "../images/userMale.png"
import userFemale from "../images/userFemale.png"
import useCurrentUser from "./useCurrentUser";
import myApi from './MyApi';
import { useActiveContext } from "./ActiveContext";
import panLogo from "../images/panelLogo.png"
import panLogoDark from "../images/panelLogoDark.png"
import { useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import Loader from './Loader';
import Logo from './Navbar';
import useIsMobile from './useIsMobile';





export default function DashboardTop({head}) {
  const isMobile = useIsMobile();
  const [currentUser, setCurrentUser] = useCurrentUser();
  const {active,setActive,sideBarDis,setSideBarDis,darkTheme,setDarkTheme} = useActiveContext();
  const [loading,setIsLoading] = useState(false);



  const navigate = useNavigate();
  const getActiveText = (activeText)=>{
  
    if(activeText === 'myAccount'){
      return 'My Account'
    }
    else if(activeText === 'help'){
      return 'Help & Support'
    }
    else{
      return active;
    }
  }
  const handleLogout = async ()=>{
    navigate('/login')
    setIsLoading(true)
    const accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');
    
    try {
      const resp = await myApi.post('/account/logout', {
        refresh_token: refreshToken,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = window.location.href.split('/').slice(0, 3).join('/') + "/login";
      setIsLoading(false);
    } catch (error) {
     
   
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = window.location.href.split('/').slice(0, 3).join('/') + "/login";
      navigate('/login')
      setIsLoading(false);
    }
  }

  // useEffect(() => {
  //   // Get the JWT token from local storage
  //   const token = localStorage.getItem('accessToken');
  //   // Make an authenticated API request
  //   myApi
  //     .get('/Profile', {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((response) => {
  //       // getUserByUserId(response.data.user.userId);
  //      setCurrentUser(response.data)
  //     
  //     })
  //     .catch((error) => {
  //    
  //       // renewAccessToken();

  //     });
  // }, []);

  // if(currentUser)

  const changeMidComp = (compName)=>{
    setActive(compName);
    navigate(`/${compName}`)
    isMobile && setSideBarDis(false);
  }

  if(loading) return <Loader/>
   return (
    <div className="top" style={{background:darkTheme&&"var(--darkBg1)"}}>
      {/* <Logo/> */}
      <div style={{cursor:active !=='dashboard'&&'pointer',width:'50%'}} className='mobNavLogo' onClick={()=>changeMidComp("dashboard")}>
      <img className="panLogo" style={{maxWidth:'30%'}} src={darkTheme?panLogoDark:panLogo} alt=""  />
      <div style={{color:'var(--iconCol)',fontSize:'0.5em'}}>Every opinion matters</div>
    </div>
      {/* <img className='panLogo' src={darkTheme?panLogoDark:panLogo} alt="" onClick={()=>setActive("dashboard")}/> */}
    {/* <div className={darkTheme &&"dark"}>{head!==undefined?head:'Dashboard'}</div> */}
    <div style={{textTransform:'capitalize',minWidth:'100px'}} className={darkTheme &&"dark"}>{getActiveText(active)}</div>
    <SearchBar/>
    <div className={darkTheme?"dark menuIcon":"menuIcon"}>
      <i className="fa-solid fa-message" onClick={()=>{setActive("message"); navigate("/message")}}></i>
      {/* <i className="fa-solid fa-bell"></i> */}
      {!darkTheme? <i className="fa-solid fa-sun" onClick={()=>{setDarkTheme(true); localStorage.setItem("theme",'dark')}}></i>: <i className="fa-solid fa-moon"  onClick={()=>{setDarkTheme(false); localStorage.setItem("theme",'light')}}></i>}
     
      {/* <i className="fa-solid fa-angle-down"></i> */}
      <i className="fa-solid fa-power-off logoutBtn"  onClick={handleLogout}></i>
    </div>
    <div className="dfr guestDiv">
      {currentUser&&<>
       <span className={darkTheme&&"dark"}>{currentUser.result.name}</span>
      <div className="pointer dfc" onClick={()=>{setActive("profile"); navigate('/profile')}}><div className="userImgCont">
      <img alt="User" src={currentUser.result.profilePic} />
      </div>
      </div>
     
       </>}
      
    </div>
    {sideBarDis?<i className="fa-solid fa-close sideBarBtn" onClick={()=>setSideBarDis(!sideBarDis)} style={{marginRight:"5%",color:darkTheme&&'var(--darkText)'}}></i>:<i className="fa-solid fa-bars pointer sideBarBtn" onClick={()=>setSideBarDis(!sideBarDis)} style={{marginRight:"5%",color:darkTheme&&'var(--darkText)'}}></i>}

  </div>
  )
}



